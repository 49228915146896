@import "../../Styles/colors.scss";

.display {
    background-color: $secondary-blue;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 4px 10px;
    border-radius: 3px;
    cursor: pointer;

    .selected {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .arrow {
        transform: rotate(-90deg);
        transition-duration: 0.3s;
    }

    .arrowActive {
        transform: rotate(90deg);
        transition-duration: 0.3s;
    }
}

.dropdown {
    position: relative;
    background-color: $secondary-blue;
    
    .option { 
        text-align: center;
        padding: 3px 15px;
        cursor: pointer;
    }

    .option:hover {
        background-color: #003eb9;
    }
}
