@import "../../Styles/fonts.scss";
@import "../../Styles/colors.scss";
@import "../../Styles/breakpoints.scss";

@media (max-width: $tablet - 1) {
  .content2 {
    .goBack {
      font-size: 12px;
      color: #3d91ef;
      font-family: $font-nasalization;
      text-align: left;
      width: 100%;
      cursor: pointer;
      text-decoration: none;
      padding: 10vw 0 0 2.5vw;
    }

    .goBack:hover {
      text-decoration: underline;
    }

    .title {
      font-size: 22px;
      color: white;
      font-family: $font-nasalization;
      text-align: left;
      width: 100%;
      padding: 2vw 0 2vw 2.5vw;
    }

    .rectangle {
      border-image: linear-gradient(
          180deg,
          #0146d5 0%,
          rgba(1, 70, 213, 0) 100%
        )
        1;
      border-top: 3px solid transparent;
      border-bottom: none;
      background: linear-gradient(
        180deg,
        rgba(4, 18, 55, 0.65) 0%,
        rgba(4, 18, 55, 0) 100%
      );

      .link {
        padding: 30px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media (min-width: $tablet) and (max-width: $laptop - 1) {
  .content2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .goBack {
      font-size: 10px;
      color: #3d91ef;
      font-family: $font-nasalization;
      text-align: left;
      width: 100%;
      cursor: pointer;
      text-decoration: none;
      padding: 2vw 0 0 2.5vw;
    }

    .goBack:hover {
      text-decoration: underline;
    }

    .title {
      font-size: 25px;
      color: white;
      font-family: $font-nasalization;
      text-align: left;
      width: 100%;
      padding: 0.3vw 0 1vw 2.5vw;
    }

    .rectangle {
      width: 95vw;
      border-image: linear-gradient(
          180deg,
          #0146d5 0%,
          rgba(1, 70, 213, 0) 100%
        )
        1;
      border-top: 2px solid;
      border-bottom: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-right: 2.1px solid;
      justify-content: center;
      gap: 50px;
      background: linear-gradient(
        180deg,
        rgba(4, 18, 55, 0.65) 0%,
        rgba(4, 18, 55, 0) 100%
      );
      border-left: 2.2px solid;

      .link {
        padding: 30px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media (min-width: $laptop) and (max-width: $desktop - 1) {
  .content2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .goBack {
      font-size: 14px;
      color: #3d91ef;
      font-family: $font-nasalization;
      text-align: left;
      width: 100%;
      cursor: pointer;
      text-decoration: none;
      padding: 2vw 0 0 2.5vw;
    }

    .goBack:hover {
      text-decoration: underline;
    }

    .title {
      font-size: 25px;
      color: white;
      font-family: $font-nasalization;
      text-align: left;
      width: 100%;
      padding: 0.3vw 0 1vw 2.5vw;
    }

    .rectangle {
      width: 95vw;
      border-image: linear-gradient(
          180deg,
          #0146d5 0%,
          rgba(1, 70, 213, 0) 100%
        )
        1;
      border-top: 2px solid;
      border-bottom: none;
      display: flex;
      flex-direction: row;
      border-right: 2.1px solid;
      align-items: center;
      justify-content: center;
      gap: 50px;
      border-left: 2.3px solid;
      background: linear-gradient(
        180deg,
        rgba(4, 18, 55, 0.65) 0%,
        rgba(4, 18, 55, 0) 100%
      );

      .link {
        padding: 30px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media (min-width: $desktop) {
  .container {
    display: flex;
    margin-top: 30px;
    justify-content: space-around;

    .nfts {
      width: 84vw;
      min-height: 300px;
      background: linear-gradient(180deg, #041237 0%, rgba(4, 18, 55, 0) 100%);
      border: 1.5px solid;
      border-image: linear-gradient(
          180deg,
          #0146d5 0%,
          rgba(1, 70, 213, 0) 100%
        )
        1;
      border-bottom: none;
    }
  }
}
