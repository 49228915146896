@import '../../Styles/fonts.scss';
@import '../../Styles/colors.scss';

.botonGenerico {
    background-color: $primary-blue;
    border: none;
    color: white;
    padding: 10px 25px;
    font-family: $font-monkyta;
    font-size: 1.3em;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    transition: 0.3s;

    &:hover{
        -webkit-box-shadow:0px 0px 12px 6px rgba(48,0,150, 0.8);
        -moz-box-shadow: 0px 0px 12px 6px rgba(48,0,150, 0.8);
        box-shadow: 0px 0px 12px 6px rgba(48,0,150, 0.8);
    }
}

.botonGenericoMainPage {

    // Estos son los estilos del botón que se mostrara
    position: relative;
    background-color: $primary-blue;
    border: none;
    color: white;
    padding: 10px 25px;
    font-family: $font-monkyta;
    font-size: 1.3em;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    overflow: visible;
    //Le colocamos z-indez 1 para que se muestre por encima de lo que aparecerá en after y before
    z-index: 1;
    
    //En general after y before tienen z-index -1 y el after quedará por encima del before.
    //Es como un laburo por capas => 1)Aparece before, luego 2)After y 3)El contenido propio
    &:before {
        z-index: -1;
        content: '';
        background: linear-gradient(90deg, $primary-blue ,$account-border, $glow-modal, $account-border, $primary-blue);
        position: absolute;
        top: -5px;
        left:-5px;
        background-size: 500%;
        filter: blur(3px);
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        animation: glowing 10s linear infinite;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        border-radius: 10px;
    }

    //Modificamos la opacity de lo que se ve en el before cuando hacemos un hover
    &:hover:before {
        opacity: 1;
    }

    &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: $primary-blue;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        z-index: -1;
    }
 
    //La animación del before
    @keyframes glowing {
        0% { background-position: 0 0; }
        100% { background-position: 500% 0; }
    } 

}

.botonGenericoModal {
    // Estos son los estilos del botón que se mostrara
    position: relative;
    background-color: $primary-blue;
    border: none;
    color: white;
    padding: 10px 25px;
    font-family: $font-monkyta;
    font-size: 1.3em;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    overflow: visible;
    //Le colocamos z-indez 1 para que se muestre por encima de lo que aparecerá en after y before
    z-index: 1;
    
    //En general after y before tienen z-index -1 y el after quedará por encima del before.
    //Es como un laburo por capas => 1)Aparece before, luego 2)After y 3)El contenido propio
    &:before {
        z-index: -1;
        content: '';
        background: linear-gradient(90deg, $glow-modal, $glow-modal-light, $glow-modal);
        position: absolute;
        top: -5px;
        left:-5px;
        background-size: 300%;
        filter: blur(3px);
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        animation: glowing 3s linear infinite;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        border-radius: 10px;
    }

    //Modificamos la opacity de lo que se ve en el before cuando hacemos un hover
    &:hover:before {
        opacity: 1;
    }

    &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: $primary-blue;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        z-index: -1;
    }
 
    //La animación del before
    @keyframes glowing {
        0% { background-position: 0 0; }
        100% { background-position: 300% 0; }
    } 

}