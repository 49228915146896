@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/breakpoints.scss";

@media (max-width: $mobile - 1) {
  .tokenomicsContainer {
    background-color: rgba(0, 2, 36, 0.939);
    background-image: url("../../../../Assets/img/epic_blur.png");
    background-size: cover;
    text-align: center;
    color: white;
    font-family: $font-monkyta;
    padding-top: 75px;

    h2 {
      font-size: 30px;
      font-weight: 400;
    }

    p {
      padding: 0 25px;
      margin-top: 15px;
      font-size: 16px;
      font-weight: 400;
    }

    .tokenomics {
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      gap: 30px;
      padding-bottom: 100px;


      .rectangle {
        background: #081f9180;
        width: 245px;
        height: 140px;
        border: 4px solid #0149db;
        box-shadow: 0px 0px 15px 0px #a427e340 inset;
        border-radius: 10px;
      }

      .circle {
        height: 130px;
        width: 130px;
        border-radius: 999px;
        background-color: #c4c4c4;
      }

      .rectangleContainer {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .rectangle {
          width: 270px;
          height: 85px;
        }
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: $mobile) and (max-width: $tablet - 1) {
  .tokenomicsContainer {
    background-color: rgba(0, 2, 36, 0.939);
    background-image: url("../../../../Assets/img/epic_blur.png");
    background-size: cover;
    text-align: center;
    color: white;
    font-family: $font-monkyta;
    padding-top: 75px;

    h2 {
      font-size: 30px;
      font-weight: 400;
    }

    p {
      padding: 0 25px;
      margin-top: 15px;
      font-size: 16px;
      font-weight: 400;
    }

    .tokenomics {
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      gap: 30px;
      padding-bottom: 100px;


      .rectangle {
        background: #081f9180;
        width: 245px;
        height: 140px;
        border: 4px solid #0149db;
        box-shadow: 0px 0px 15px 0px #a427e340 inset;
        border-radius: 10px;
      }

      .circle {
        height: 130px;
        width: 130px;
        border-radius: 999px;
        background-color: #c4c4c4;
      }

      .rectangleContainer {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .rectangle {
          width: 270px;
          height: 85px;
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $tablet) and (max-width: $desktop - 1) {
  .tokenomicsContainer {
    background-color: rgba(0, 2, 36, 0.939);
    background-image: url("../../../../Assets/img/epic_blur.png");
    background-size: cover;
    height: 600px;
    text-align: center;
    color: white;
    font-family: $font-monkyta;
    padding-top: 75px;

    h2 {
      font-size: 30px;
      font-weight: 400;
    }

    p {
      margin-top: 15px;
      font-size: 16px;
      font-weight: 400;
    }

    .tokenomics {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 30px;
      padding: 0 20px;
      height: 60%;

      .rectangle {
        background: #081f9180;
        width: 245px;
        height: 140px;
        border: 4px solid #0149db;
        box-shadow: 0px 0px 15px 0px #a427e340 inset;
        border-radius: 10px;
      }

      .circle {
        height: 130px;
        width: 130px;
        border-radius: 999px;
        background-color: #c4c4c4;
      }

      .rectangleContainer {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .rectangle {
          width: 270px;
          height: 85px;
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $desktop) and (max-width: $HD - 1) {
  .tokenomicsContainer {
    background-color: rgba(0, 2, 36, 0.939);
    background-image: url("../../../../Assets/img/epic_blur.png");
    background-size: cover;
    height: 600px;
    text-align: center;
    color: white;
    font-family: $font-monkyta;
    padding-top: 75px;

    h2 {
      font-size: 35px;
      font-weight: 400;
    }

    p {
      margin-top: 15px;
      font-size: 16px;
      font-weight: 400;
    }

    .tokenomics {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 30px;
      padding: 0 20px;
      height: 70%;

      .rectangle {
        background: #081f9180;
        width: 300px;
        height: 180px;
        border: 4px solid #0149db;
        box-shadow: 0px 0px 15px 0px #a427e340 inset;
        border-radius: 10px;
      }

      .circle {
        height: 155px;
        width: 155px;
        border-radius: 999px;
        background-color: #c4c4c4;
      }

      .rectangleContainer {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .rectangle {
          width: 300px;
          height: 85px;
        }
      }
    }
  }
}

@media (min-width: $HD) {
  .tokenomicsContainer {
    background-color: rgba(0, 2, 36, 0.939);
    background-image: url("../../../../Assets/img/epic_blur.png");
    background-size: cover;
    height: 650px;
    text-align: center;
    color: white;
    font-family: $font-monkyta;
    padding-top: 75px;

    h2 {
      font-size: 35px;
      font-weight: 400;
    }

    p {
      margin-top: 15px;
      font-size: 16px;
      font-weight: 400;
    }

    .tokenomics {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 30px;
      padding: 0 20px;
      height: 70%;

      .rectangle {
        background: #081f9180;
        width: 350px;
        height: 230px;
        border: 4px solid #0149db;
        box-shadow: 0px 0px 15px 0px #a427e340 inset;
        border-radius: 10px;
      }

      .circle {
        height: 180px;
        width: 180px;
        border-radius: 999px;
        background-color: #c4c4c4;
      }

      .rectangleContainer {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .rectangle {
          width: 350px;
          height: 100px;
        }
      }
    }
  }
}
