@import "../../Styles/colors.scss";
@import "../../Styles/fonts.scss";
$mobile: 576px;
$tablet: 767px;
$laptop: 992px;
$desktop: 1200px;
$hd: 1400px;

// Extra mall devices (< 576px)
@media (max-width: $mobile - 1) {
  .divRow3 {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .imgModal {
    height: 150px;
  }
  .text {
    font-family: $font-nasalization;
    font-size: 18px;
    color: #ffff;
    font-weight: normal;
  }
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .divRow1 {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .divRow2 {
    gap: 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .errorMessage {
    font-size: 14px;
    color: #730909;
    font-family: $font-nasalization;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    gap: 20px;
  }

  .textResponsive {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: $mobile) and (max-width: $tablet - 1) {
  .divRow3 {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .imgModal {
    height: 150px;
  }
  .text {
    font-family: $font-nasalization;
    font-size: 18px;
    color: #ffff;
    font-weight: normal;
  }
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .divRow1 {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .divRow2 {
    gap: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .errorMessage {
    font-size: 14px;
    color: #730909;
    font-family: $font-nasalization;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
    gap: 30px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $tablet) and (max-width: $laptop - 1) {
  .divRow3 {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .imgModal {
    height: 150px;
  }
  .text {
    font-family: $font-nasalization;
    font-size: 18px;
    color: #ffff;
    font-weight: normal;
  }
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .divRow1 {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .divRow2 {
    gap: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }
  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .errorMessage {
    font-size: 20px;
    color: #730909;
    font-family: $font-nasalization;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
    gap: 30px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $laptop) and (max-width: $desktop - 1) {
  .divRow3 {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .imgModal {
    height: 150px;
  }
  .text {
    font-family: $font-nasalization;
    font-size: 18px;
    color: #ffff;
    font-weight: normal;
  }
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .divRow1 {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .divRow2 {
    gap: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .errorMessage {
    font-size: 20px;
    color: #730909;
    font-family: $font-nasalization;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
    gap: 30px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $desktop) and (max-width: $hd - 1) {
  .divRow3 {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .imgModal {
    height: 150px;
  }
  .text {
    font-family: $font-nasalization;
    font-size: 18px;
    color: #ffff;
    font-weight: normal;
  }

  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .divRow1 {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .divRow2 {
    gap: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 105vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .errorMessage {
    font-size: 20px;
    color: #730909;
    font-family: $font-nasalization;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
    gap: 30px;
  }
}

@media (min-width: $hd) {
  .divRow3 {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .imgModal {
    height: 150px;
  }
  .text {
    font-family: $font-nasalization;
    font-size: 18px;
    color: #ffff;
    font-weight: normal;
  }
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .divRow1 {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .divRow2 {
    gap: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .errorMessage {
    font-size: 20px;
    color: #730909;
    font-family: $font-nasalization;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
    gap: 30px;
  }
}
