@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/colors.scss";

.parentContainerModal {
    padding-top: 80px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}
.tableList {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    text-align: center;
    position: relative;
    border-spacing: 15px;
    border-radius: 4px;
    thead > tr > th {
        font-size: 20px;
        font-weight: 400;
        font-family: $font-nasalization;
        color: #fff;
    }
    tbody > tr > td {
        margin: 5px;
        font-size: 20px;
        font-family: $font-nasalization;
        background-color: rgba(8, 31, 145, 0.22);
        color: #fff;
    }
}
