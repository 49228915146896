@import "../../Styles/colors.scss";
@import "../../Styles/fonts.scss";
$mobile: 576px;
$tablet: 767px;
$laptop: 992px;
$desktop: 1200px;
$hd: 1400px;

.imgContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0px;
  gap: 30px;
  .imgModal {
    height: 150px;
  }
}
// Extra mall devices (< 576px)
@media (max-width: $mobile - 1) {
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }

  .message {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: $font-route159;
    font-size: 13px;
    color: $sub-message;
    text-align: center;

    a {
      color: $sub-message;
    }
  }
  .inputContainer {
    width: 100%;
    padding: 0 20px;
  }
  .errorMessage {
    font-size: 15px;
    color: #730909;
    font-family: $font-nasalization;
  }
  .contentText {
    font-size: 14px;
    padding: 6px 0 6px 15px;
    font-family: $font-nasalization;
    text-align: "left";
    color: white;
  }
  .contentTextCenter {
    font-size: 14px;
    padding: 10px;
    font-family: $font-nasalization;
    text-align: "center";
    color: white;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: $mobile) and (max-width: $tablet - 1) {
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }

  .message {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: $font-route159;
    font-size: 13px;
    color: $sub-message;
    text-align: center;

    a {
      color: $sub-message;
    }
  }
  .inputContainer {
    width: 100%;
    padding: 0 20px;
  }
  .errorMessage {
    font-size: 15px;
    color: #730909;
    font-family: $font-nasalization;
  }
  .contentText {
    font-size: 14px;
    padding: 6px 0 6px 15px;
    font-family: $font-nasalization;
    text-align: "left";
    color: white;
  }
  .contentTextCenter {
    font-size: 18px;
    padding: 20px;
    font-family: $font-nasalization;
    text-align: "center";
    color: white;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $tablet) and (max-width: $laptop - 1) {
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }

  .message {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: $font-route159;
    font-size: 13px;
    color: $sub-message;
    text-align: center;

    a {
      color: $sub-message;
    }
  }
  .inputContainer {
    width: 100%;
    padding: 0 20px;
  }
  .errorMessage {
    font-size: 15px;
    color: #730909;
    font-family: $font-nasalization;
  }
  .contentText {
    font-size: 14px;
    padding: 6px 0 6px 15px;
    font-family: $font-nasalization;
    text-align: "left";
    color: white;
  }
  .contentTextCenter {
    font-size: 18px;
    padding: 20px;
    font-family: $font-nasalization;
    text-align: "center";
    color: white;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $laptop) and (max-width: $desktop - 1) {
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }

  .message {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: $font-route159;
    font-size: 13px;
    color: $sub-message;
    text-align: center;

    a {
      color: $sub-message;
    }
  }
  .inputContainer {
    width: 100%;
    padding: 0 20px;
  }
  .errorMessage {
    font-size: 20px;
    color: #730909;
    font-family: $font-nasalization;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $desktop) and (max-width: $hd - 1) {
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 105vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }

  .message {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: $font-route159;
    font-size: 13px;
    color: $sub-message;
    text-align: center;

    a {
      color: $sub-message;
    }
  }
  .inputContainer {
    width: 100%;
    padding: 0 20px;
  }
  .errorMessage {
    font-size: 20px;
    color: #730909;
    font-family: $font-nasalization;
  }
  .contentText {
    width: 100%;
    font-size: 20px;
    padding: 20px 0 5px 20px;
    font-family: $font-nasalization;
    text-align: left;
    color: white;
  }
  .contentTextCenter {
    font-size: 18px;
    padding: 20px;
    font-family: $font-nasalization;
    color: white;
    text-align: "center";
  }
  .textResponsive {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 20px;
  }
}

@media (min-width: $hd) {
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }

  .message {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: $font-route159;
    font-size: 13px;
    color: $sub-message;
    text-align: center;

    a {
      color: $sub-message;
    }
  }
  .inputContainer {
    width: 100%;
    padding: 0 20px;
  }
  .errorMessage {
    font-size: 20px;
    color: #730909;
    font-family: $font-nasalization;
  }
  .contentText {
    width: 100%;
    font-size: 20px;
    padding: 20px;
    font-family: $font-nasalization;
    text-align: left;
    color: white;
  }
  .contentTextCenter {
    font-size: 18px;
    padding: 20px;
    font-family: $font-nasalization;
    text-align: center;
    color: white;
  }
  .textResponsive {
    text-align: left;
    font-size: 14px;
    padding: 15px 20px;
  }
}

.subtitle {
  text-align: center;
  color: white;
  font-family: $font-nasalization;
}
