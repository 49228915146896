@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/colors.scss";
@import "../../../../Styles/breakpoints.scss";

.container {
    width: 175px;
    font-family: $font-nasalization;
    color: white;
    height: fit-content;
    border-radius: 10px;

    .title {
        width: 100%;
        background-color: $account-border;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        height: 50px;

        h2 {
            font-size: 15px;
        }
        h3 {
            cursor: pointer;
            font-size: 20px;
        }
    }

    .filters {

        width: 100%;
        padding: 30px 0px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: left;
        justify-content: center;
        background: #1892f01f;
        border: 1.5px solid #0149db;
        // border-radius: 0px 0px 10px 10px;

        .filter {
            display: flex;
            align-items: center;
            margin-left: 35px;
            gap: 10px;
            font-size: 13px;
        }

        input[type="checkbox"] {
            -webkit-appearance: none;
            appearance: none;
            background-color: #0149db85;
            margin: 0;
            font: inherit;
            color: #0149db85;
            width: 1.15em;
            height: 1.15em;
            border: 0.15em solid #0149db;
            border-radius: 0.15em;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        input[type="checkbox"]:checked:after {
            content: "X";
            color: #fff;
            width: 100%;
            text-align: center;
            left: 50%;
            top: 50%;
            font-size: 8px;
            transition-duration: 0.3s;
        }
    }
}

.btnFilter {
    z-index: 2;
    height: 40px;
    background: #0149db85;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 2px solid #0149db;
    cursor: pointer;

}

@media (max-width: $desktop - 1) {
    .menuFilter {
        z-index: 2;
        width: 150px;
        right: 0;
        margin-top: 70px;
        margin-right: 20px;
        position: absolute;
        border-radius: 5px;
        background: #1892f069;
        display: flex;
        flex-direction: column;
        margin-left: 20px;

        h2 {
            text-align: center;
            font-family: $font-nasalization;
            color: white;
            font-size: 18px;
            background: #0149db;
            padding: 10px 0;
        }
    }
}

@media (min-width: $desktop) {
    .menuFilter {
        z-index: 2;
        width: 150px;
        right: 0;
        margin-top: 70px;
        margin-right: 65px;
        position: absolute;
        border-radius: 5px;
        background: #1892f069;
        display: flex;
        flex-direction: column;
        margin-left: 20px;

        h2 {
            text-align: center;
            font-family: $font-nasalization;
            color: white;
            font-size: 18px;
            background: #0149db;
            padding: 10px 0;
        }
    }
}

.filtersMobile {
    border: 2px solid #0149db;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 25px 0;
    padding-left: 20px;
    border-top: none;
/* 
    .filter {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 13px;
        font-family: $font-nasalization;
        color: white;
    }

    input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: #0149db85;
        margin: 0;
        font: inherit;
        color: #0149db85;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid #0149db;
        border-radius: 0.15em;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type="checkbox"]:checked:after {
        content: "X";
        color: #fff;
        width: 100%;
        text-align: center;
        left: 50%;
        top: 50%;
        font-size: 8px;
        transition-duration: 0.3s;
    } */
}

.bg {
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #010320c2;
    position: absolute;
    top: 0;
    z-index: 1;
}
