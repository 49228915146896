@keyframes easyZoom {
    0% {
      transform: scale(1);
    }
    100%{
      transform: scale(1.10);
    }
}

@keyframes modalFade {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes modalLeftRight {
  0% {
      transform: translateX(-70px)
  }
  100% {
      transform: translateX(0px)
  }
}