@import "../../../../Styles/colors.scss";
@import "../../../../Styles/fonts.scss";

$mobile: 576px;
$tablet: 767px;
$laptop: 992px;
$desktop: 1200px;
$hd: 1400px;

@media (max-width: $mobile - 1){

    .header {
        display: flex;
        position: relative;
        padding-top: 150px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: black;
        color: white;
        gap: 30px;
        width: 100vw;
        font-family: $font-monkyta;
        h1{
            text-align: center;
            font-size: 40px;
        }
        h3{
            font-size: 20px;
        }    
    }

    .imageHeader {
        overflow-y: hidden;
        height: 210px;
        width: 100%;
        background-image: url("../../../../Assets/img/headPrivacy.png") bottom center repeat #000;
    }

    .container {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      z-index: 0;
      
      ::-webkit-scrollbar {
        display: none;
      }
      footer {
        width: 100%;
        clear: both;
        position: relative;
        background: url("../../../../Assets/img/bg-footer.jpg") top center no-repeat #000;
        overflow: hidden;
        padding: 400px 20px 0px 20px;
        width: 100%;
        margin-top: 0px;
        color: #fff;
        text-align: center;
        display: flex;
    
        article {
          width: 450px;
          clear: both;
          display: block;
          margin: 0 auto;
          height: 100%;
    
          section {
            height: 100%;
    
            .div1 {
              padding-bottom: 30px;
              z-index: 99999;
    
              h2 {
                font-size: 3em;
                padding-bottom: 30px;
                line-height: 0.9em;
                font-family: $font-monkyta;
              }
    
              .social {
                font-family: $font-route159;
                text-align: center;
    
                a {
                  text-decoration: none;
                  color: #fff;
                  display: inline-block;
                  font-size: 0.9em;
                  padding: 10px;
                  background-color: transparent;
                  transition: all 300ms ease;
    
                  svg {
                    width: 26px;
                    padding: 5px 3px;
                  }
                }
              }
    
              .brands {
                font-family: $font-route159;
                display: block;
                text-decoration: none;
                color: white;
    
                img {
                  padding: 55px 20px 20px;
                  max-width: 200px;
                  border: 0;
                }
              }
    
              .widget {
                font-family: $font-route159;
                display: block;
                font-size: 1em;
    
                p {
                  font-size: 13px;
                  padding: 8px 0 10px;
                  max-width: 800px;
                  margin: 0 auto;
                }
              }
    
              .menu {
                display: block;
    
                ul {
                  list-style: none;
                  margin-block-start: 0;
                  margin-block-end: 0;
                  margin-inline-start: 0;
                  margin-inline-end: 0;
                  padding-inline-start: 0;
    
                  li {
                    display: inline-block;
                    margin-right: 12px;
                    position: relative;
                    font-family: $font-route159;
    
                    a {
                      color: #fff;
                      text-decoration: none;
                      list-style: none;
                      transition: all 300ms ease;
                      background-color: transparent;
                      cursor: pointer;
                      font-size: 13px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    
      .bear {
        display: none;
      }
    }

    .mainContent{
        background-color: #D93DE2;
    }
}

@media (min-width: $mobile) and (max-width: $hd - 1){
    .container {
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 0;

    .imageHeader {
        overflow-y: hidden;
        height: 200px;
        width: 100%;
        image {
            width: 100%;
            z-index: 2;
        }
    }

    .header {
        display: flex;
        position: relative;
        padding-top: 120px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: black;
        color: white;
        gap: 30px;
        width: 100vw;
        font-family: $font-monkyta;
        h1{
            font-size: 40px;
        }
        h3{
            font-size: 25px;
        }
    }
        
        ::-webkit-scrollbar {
          display: none;
        }   

    footer {
        width: 100%;
        clear: both;
        position: relative;
        background: url("../../../../Assets/img/bg-footer.jpg") top center no-repeat #000;
        overflow: hidden;
        padding: 500px 0px 0px 50px;
        margin-top: 0px;
        width: 100%;
        color: #fff;
        text-align: center;
    
        article {
          width: 960px;
          clear: both;
          display: block;
          margin: 0 auto;
          height: 100%;
    
          section {
            height: 100%;
    
            .div1 {
              padding-bottom: 30px;
    
              h2 {
                font-size: 3em;
                padding-bottom: 30px;
                line-height: 0.9em;
                font-family: $font-monkyta;
              }
    
              .social {
                font-family: $font-route159;
                text-align: center;
    
                a {
                  text-decoration: none;
                  color: #fff;
                  display: inline-block;
                  font-size: 0.9em;
                  padding: 10px;
                  background-color: transparent;
                  transition: all 300ms ease;
    
                  svg {
                    width: 26px;
                    padding: 5px 3px;
                  }
                }
              }
    
              .brands {
                font-family: $font-route159;
                display: block;
                text-decoration: none;
                color: white;
    
                img {
                  padding: 55px 20px 20px;
                  max-width: 200px;
                  border: 0;
                }
              }
    
              .widget {
                font-family: $font-route159;
                display: block;
                font-size: 1em;
    
                p {
                  padding: 8px 0 10px;
                  max-width: 800px;
                  margin: 0 auto;
                }
              }
    
              .menu {
                display: block;
    
                ul {
                  list-style: none;
                  margin-block-start: 0;
                  margin-block-end: 0;
                  margin-inline-start: 0;
                  margin-inline-end: 0;
                  padding-inline-start: 0;
    
                  li {
                    display: inline-block;
                    margin-right: 12px;
                    position: relative;
                    font-family: $font-route159;
    
                    a {
                      color: #fff;
                      text-decoration: none;
                      list-style: none;
                      transition: all 300ms ease;
                      background-color: transparent;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }
    
      .bear {
        position: absolute;
        bottom: 0;
        right: -130px;
        width: 430px;
        margin: 0;
      }
    }

    .mainContent{
        background-color: #D93DE2;
    }
}

@media (min-width: $hd){
    .container {
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 0;

    .imageHeader {
        margin-bottom: -5px;
        overflow-y: hidden;
        height: fit-content;
        width: 100%;
        img {
            width: 100%;
            z-index: 2;
        }
    }

    .header {
        display: flex;
        position: relative;
        padding-top: 120px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: black;
        color: white;
        gap: 30px;
        width: 100vw;
        font-family: $font-monkyta;
        h1{
            font-size: 60px;
        }
        h3{
            font-size: 40px;
        }
    }
        
        ::-webkit-scrollbar {
          display: none;
        }   

    footer {
        width: 100%;
        clear: both;
        position: relative;
        background: url("../../../../Assets/img/bg-footer.jpg") top center no-repeat #000;
        overflow: hidden;
        padding: 500px 0px 0px 50px;
        margin-top: 0px;
        width: 100%;
        color: #fff;
        text-align: center;
    
        article {
          width: 960px;
          clear: both;
          display: block;
          margin: 0 auto;
          height: 100%;
    
          section {
            height: 100%;
    
            .div1 {
              padding-bottom: 30px;
    
              h2 {
                font-size: 3em;
                padding-bottom: 30px;
                line-height: 0.9em;
                font-family: $font-monkyta;
              }
    
              .social {
                font-family: $font-route159;
                text-align: center;
    
                a {
                  text-decoration: none;
                  color: #fff;
                  display: inline-block;
                  font-size: 0.9em;
                  padding: 10px;
                  background-color: transparent;
                  transition: all 300ms ease;
    
                  svg {
                    width: 26px;
                    padding: 5px 3px;
                  }
                }
              }
    
              .brands {
                font-family: $font-route159;
                display: block;
                text-decoration: none;
                color: white;
    
                img {
                  padding: 55px 20px 20px;
                  max-width: 200px;
                  border: 0;
                }
              }
    
              .widget {
                font-family: $font-route159;
                display: block;
                font-size: 1em;
    
                p {
                  padding: 8px 0 10px;
                  max-width: 800px;
                  margin: 0 auto;
                }
              }
    
              .menu {
                display: block;
    
                ul {
                  list-style: none;
                  margin-block-start: 0;
                  margin-block-end: 0;
                  margin-inline-start: 0;
                  margin-inline-end: 0;
                  padding-inline-start: 0;
    
                  li {
                    display: inline-block;
                    margin-right: 12px;
                    position: relative;
                    font-family: $font-route159;
    
                    a {
                      color: #fff;
                      text-decoration: none;
                      list-style: none;
                      transition: all 300ms ease;
                      background-color: transparent;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }
    
      .bear {
        position: absolute;
        bottom: 0;
        right: -130px;
        width: 430px;
        margin: 0;
      }
    }

    .mainContent{
        background-color: #D93DE2;
    }
}