@import '../../../../Styles/breakpoints.scss';

@media (max-width: $mobile - 1) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        .content {
            p {
                font-size: 16px;
            }
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;
        
            .imagen {
                height: 60px;
                object-fit: contain;
            }
        }
    }
}

@media (min-width: $mobile) {
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
    
        .content {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 20px;

            p {
                font-size: 22px;
            }

            .imagen {
                height: 80px;
                object-fit: contain;
            }

        }
    }
}
