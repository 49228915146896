@import "../../../../Styles/breakpoints.scss";
@import "../../../../Styles/fonts.scss";

@media (max-width: $mobile - 1) {
  ::-webkit-scrollbar {
    display: none;
  }

  .content1 {
    text-align: center;
    width: 100%;
    top: 0;
    height: 105vh;
    overflow-y: hidden;
  }

  .battle {
    background-image: url("../../../../Assets/img/Bg-Home.png");
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    overflow-y: hidden;
    height: 105vh;
    position: relative;
    background-size: cover;
    gap: 20px;

    .homePic {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      object-fit: contain;
      animation: easyZoom 20s ease-in-out 0s infinite alternate;
    }

    .bpBrand {
      width: 265px;
      z-index: 1;
    }

    .buttons {
      margin-top: -45px;
      z-index: 2;
      display: flex;
      gap: 10px;

      a {
        text-decoration: none;
      }

      .button {
        font-family: $font-monkyta;
        font-size: 18px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background: #081f91;
        color: white;
        border-radius: 3px;
        padding: 5px 10px;
        border: 2px solid #3d92ef;
        box-shadow: 0px 0px 15px 0px #3d91ef inset;

        cursor: pointer;

        svg {
          width: 20px;
        }
      }
    }

    .contractContainer {
      display: flex;
      flex-direction: column;
      gap: 3px;
      font-family: $font-monkyta;
      color: white;


      .contract {
        font-size: 12px;
        display: flex;
        gap: 10px;
        background: #081f9180;
        border: 1px solid #3d91ef;
        box-shadow: 0px 0px 10px 0px #a427e340 inset;
        border-radius: 3px;

        p {
          padding: 10px;
        }
      }
    }

    .tradeNow {
      cursor: pointer;
      font-family: $font-monkyta;
      font-size: 16px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      background: #081f91;
      color: white;
      border-radius: 3px;
      padding: 10px 15px;
      border: 2px solid #3d92ef;
      box-shadow: 0px 0px 15px 0px #3d91ef inset;

    }
  }
}

@media (min-width: $mobile) and (max-width: $tablet - 1) {
  ::-webkit-scrollbar {
    display: none;
  }

  .content1 {
    text-align: center;
    width: 100%;
    top: 0;
    height: 105vh;
    overflow-y: hidden;
  }

  .battle {
    background-image: url("../../../../Assets/img/Bg-Home.png");
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    overflow-y: hidden;
    height: 105vh;
    position: relative;
    background-size: cover;
    gap: 20px;

    .homePic {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      object-fit: contain;
      animation: easyZoom 20s ease-in-out 0s infinite alternate;
    }

    .bpBrand {
      width: 350px;
      z-index: 1;
    }

    .buttons {
      margin-top: -45px;
      z-index: 2;
      display: flex;
      gap: 10px;

      a {
        text-decoration: none;
      }

      .button {
        font-family: $font-monkyta;
        font-size: 22px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background: #081f91;
        color: white;
        border-radius: 3px;
        padding: 10px 15px;
        border: 2px solid #3d92ef;
        box-shadow: 0px 0px 15px 0px #3d91ef inset;

        cursor: pointer;

        svg {
          width: 20px;
        }
      }
    }

    .contractContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
      font-family: $font-monkyta;
      color: white;

      .contract {
        display: flex;
        gap: 10px;
        background: #081f9180;
        border: 1px solid #3d91ef;
        box-shadow: 0px 0px 15px 0px #a427e340 inset;
        border-radius: 3px;

        p {
          padding: 10px;
        }
      }
    }

    .tradeNow {
      cursor: pointer;
      font-family: $font-monkyta;
      font-size: 22px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      background: #081f91;
      color: white;
      border-radius: 3px;
      padding: 10px 15px;
      border: 2px solid #3d92ef;
      box-shadow: 0px 0px 15px 0px #3d91ef inset;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (min-width: $tablet) and (max-width: $desktop - 1) {
  ::-webkit-scrollbar {
    display: none;
  }

  .content1 {
    text-align: center;
    width: 100%;
    top: 0;
    height: 105vh;
    overflow-y: hidden;
  }

  .battle {
    background-image: url("../../../../Assets/img/Bg-Home.png");
    display: flex;
    padding-left: 30px;
    padding-top: 65px;
    flex-direction: column;
    width: 100%;
    overflow-y: hidden;
    height: 105vh;
    position: relative;
    background-size: cover;
    gap: 20px;

    .homePic {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      object-fit: contain;
      animation: easyZoom 20s ease-in-out 0s infinite alternate;
    }

    .bpBrand {
      width: 350px;
      z-index: 1;
    }

    .buttons {
      margin-top: -45px;
      z-index: 2;
      display: flex;
      gap: 10px;

      a {
        text-decoration: none;
      }

      .button {
        font-family: $font-monkyta;
        font-size: 22px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background: #081f91;
        color: white;
        border-radius: 3px;
        padding: 10px 15px;
        border: 2px solid #3d92ef;
        box-shadow: 0px 0px 15px 0px #3d91ef inset;

        cursor: pointer;

        svg {
          width: 20px;
        }
      }
    }

    .contractContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
      font-family: $font-monkyta;
      color: white;

      .contract {
        display: flex;
        gap: 10px;
        background: #081f9180;
        border: 1px solid #3d91ef;
        box-shadow: 0px 0px 15px 0px #a427e340 inset;
        border-radius: 3px;

        p {
          padding: 10px;
        }
      }
    }

    .tradeNow {
      cursor: pointer;
      font-family: $font-monkyta;
      font-size: 22px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      background: #081f91;
      color: white;
      border-radius: 3px;
      padding: 10px 15px;
      border: 2px solid #3d92ef;
      box-shadow: 0px 0px 15px 0px #3d91ef inset;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (min-width: $desktop) and (max-width: $HD - 1) {
  ::-webkit-scrollbar {
    display: none;
  }

  .content1 {
    text-align: center;
    width: 100%;
    top: 0;
    height: 105vh;
    overflow-y: hidden;
  }

  .battle {
    background-image: url("../../../../Assets/img/Bg-Home.png");
    display: flex;
    padding-left: 30px;
    padding-top: 65px;
    flex-direction: column;
    width: 100%;
    overflow-y: hidden;
    height: 100vh;
    position: relative;
    background-size: cover;
    gap: 20px;

    .homePic {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      object-fit: contain;
      animation: easyZoom 20s ease-in-out 0s infinite alternate;
    }

    .bpBrand {
      width: 350px;
      z-index: 1;
    }

    .buttons {
      margin-top: -45px;
      z-index: 2;
      display: flex;
      gap: 10px;

      a {
        text-decoration: none;
      }

      .button {
        font-family: $font-monkyta;
        font-size: 22px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background: #081f91;
        color: white;
        border-radius: 3px;
        padding: 10px 15px;
        border: 2px solid #3d92ef;
        box-shadow: 0px 0px 15px 0px #3d91ef inset;

        cursor: pointer;

        svg {
          width: 20px;
        }
      }
    }

    .contractContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
      font-family: $font-monkyta;
      color: white;

      .contract {
        display: flex;
        gap: 10px;
        background: #081f9180;
        border: 1px solid #3d91ef;
        box-shadow: 0px 0px 15px 0px #a427e340 inset;
        border-radius: 3px;

        p {
          padding: 10px;
        }
      }
    }

    .tradeNow {
      cursor: pointer;
      font-family: $font-monkyta;
      font-size: 22px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      background: #081f91;
      color: white;
      border-radius: 3px;
      padding: 10px 15px;
      border: 2px solid #3d92ef;
      box-shadow: 0px 0px 15px 0px #3d91ef inset;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (min-width: $HD) {
  ::-webkit-scrollbar {
    display: none;
  }

  .content1 {
    text-align: center;
    width: 100%;
    top: 0;
    height: 100vh;
    overflow-y: hidden;
  }

  .battle {
    background-image: url("../../../../Assets/img/Bg-Home.png");
    display: flex;
    padding-left: 30px;
    padding-top: 5%;
    flex-direction: column;
    width: 100%;
    overflow-y: hidden;
    height: 105vh;
    position: relative;
    background-size: cover;
    gap: 20px;

    .homePic {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      object-fit: contain;
      animation: easyZoom 20s ease-in-out 0s infinite alternate;
    }

    .bpBrand {
      width: 500px;
      z-index: 1;
    }

    .buttons {
      margin-top: -75px;
      z-index: 2;
      display: flex;
      gap: 10px;

      a {
        text-decoration: none;
      }

      .button {
        font-family: $font-monkyta;
        font-size: 22px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background: #081f91;
        color: white;
        border-radius: 3px;
        padding: 10px 35px;
        border: 2px solid #3d92ef;
        box-shadow: 0px 0px 15px 0px #3d91ef inset;

        cursor: pointer;

        svg {
          width: 20px;
        }
      }
    }

    .contractContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
      font-family: $font-monkyta;
      color: white;

      .contract {
        display: flex;
        gap: 10px;
        background: #081f9180;
        border: 1px solid #3d91ef;
        box-shadow: 0px 0px 15px 0px #a427e340 inset;
        border-radius: 3px;

        p {
          padding: 10px;
        }
      }
    }

    .tradeNow {
      cursor: pointer;
      font-family: $font-monkyta;
      font-size: 22px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      background: #081f91;
      color: white;
      border-radius: 3px;
      padding: 10px 35px;
      border: 2px solid #3d92ef;
      box-shadow: 0px 0px 15px 0px #3d91ef inset;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.modalContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 49;
  background-color: #00000070;
}
