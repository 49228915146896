.order {
  transition-duration: 0.5s;
  padding: 5px 0;
  width: 100%;
  h2 {
    margin-left: 20px;
    font-size: 13px;
    font-weight: normal;
    cursor: pointer;
  }
}

.order:hover {
  width: 100%;
  background-color: #1892f07a;
}
