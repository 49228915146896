@import "../../Styles/colors.scss";
@import "../../Styles/fonts.scss";
@import "../../Styles/breakpoints.scss";
@import "../../Styles/animations.scss";

@media (max-width: $desktop - 1) {
  .container {
    top: 0;
    width: 100%;
    position: absolute;
  }
}

@media (min-width: $desktop) and (max-width: $HD - 1) {
  .container {
    top: 0;
    width: 100%;
    overflow-y: auto;
    position: absolute;
  }
}

@media (min-width: $HD) {
  .container {
    top: 0;
    width: 100%;
    overflow-y: scroll;
    position: absolute;
  }
}
