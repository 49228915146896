@import "../../Styles/breakpoints.scss";
@import "../../Styles/fonts.scss";

@media (max-width: $desktop - 1) {
  .msj {
    bottom: 0;
    position: fixed;
    right: 0;
    background-color: #3d91ef;
    color: white;
    display: flex;
    align-items: center;
    font-family: $font-nasalization;
    font-size: 12px;
    gap: 30px;
    padding: 15px;
    border-radius: 6px;
    margin-right: 25px;
    margin-bottom: 25px;

    svg {
      cursor: pointer;
    }
  }

  .subContainer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px;
    justify-content: space-around;
    padding: 0px 10px;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    overflow: visible;

    .filtersAndOrder {
      display: flex;
      flex-direction: row-reverse;
      gap: 20px;
    }

    .products {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;
      overflow: visible;
    }
  }
}

.maintenance {
  width: 100vw;
  text-align: center;
  color: black;
  background-color: #f9a91f;
  font-family: $font-nasalization;
  font-size: 12px;
  padding: 10px 20px;
}

@media (min-width: $desktop) {
  .msj {
    bottom: 0;
    position: fixed;
    right: 0;
    color: black;
    background-color: #f9a91f;
    display: flex;
    align-items: center;
    font-family: $font-nasalization;
    font-size: 12px;
    gap: 30px;
    padding: 15px;
    border-radius: 6px;
    margin-right: 25px;
    margin-bottom: 25px;

    svg {
      background-color: none;
      cursor: pointer;
    }
  }

  .subContainer {
    display: flex;
    margin-top: 30px;
    justify-content: space-around;
  }

  .container {
    flex-direction: column;
    display: flex;

    .filtersAndOrder {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .products {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}
