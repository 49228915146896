@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/breakpoints.scss";

@media (max-width: $tablet - 1) {
    .form {
        width: 100%;

        input {
            height: 40px;
            width: 95%;
            border: none;
            border-radius: 5px;
            background: #1892f01f;
            border: 1.5px solid #0149db;
            padding-left: 15px;
            color: white;
            font-family: $font-nasalization;
        }

        ::placeholder {
            font-family: $font-nasalization;
            color: white;
            opacity: 0.5;
        }
    }
}

@media (min-width: $tablet) and (max-width: $desktop - 1) {
    .form {
        width: 97%;

        input {
            height: 40px;
            width: 100%;
            border: none;
            border-radius: 5px;
            background: #1892f01f;
            border: 1.5px solid #0149db;
            padding-left: 15px;
            color: white;
            font-family: $font-nasalization;
        }

        ::placeholder {
            font-family: $font-nasalization;
            color: white;
            opacity: 0.5;
        }
    }
}

@media (min-width: $desktop) {
    .form {
        width: 100%;

        input {
            height: 40px;
            width: 100%;
            border: none;
            border-radius: 5px;
            background: #1892f01f;
            border: 1.5px solid #0149db;
            padding-left: 15px;
            color: white;
            font-family: $font-nasalization;
        }

        ::placeholder {
            font-family: $font-nasalization;
            color: white;
            opacity: 0.5;
        }
    }
}
