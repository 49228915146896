@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/breakpoints.scss";

@media (max-width: $mobile - 1) {
  .content4 {
    background: url("../../../../Assets/img/index-bg-02.jpg") bottom center
      no-repeat #020c24;
    -webkit-mask-image: url('../../../../Assets/img/headPrivacyFlipped.png'), linear-gradient(black, black);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: top, bottom;
    -webkit-mask-size: 100% 7%, 100% 94%;
    mask-image: url('../../../../Assets/img/headPrivacyFlipped.png'), linear-gradient(black, black);
    mask-position: top bottom;
    mask-size: 100% 7%, 100% 94%;
    mask-repeat: no-repeat;
    padding: 40px 0 130px 0;
    width: 100%;
    color: #fff;
    text-align: center;
    position: relative;
    top: -50px;
    margin-bottom: -50px;

    .container {
      display: flex;
      width: 100%;
      flex-direction: column-reverse;
      justify-content: space-around;
      overflow: hidden;

      h3 {
        font-size: 1.3em;
        padding: 35px;
        text-transform: uppercase;
        font-family: $font-monkyta;
      }

      .box2 {
        z-index: 2;
        width: 100%;
        margin: 0;
        text-align: left;
        position: relative;

        p {
          padding: 0 35px 35px;
          z-index: 50;
          word-spacing: 5px;
          margin: 0 auto;
          position: relative;
          max-width: 800px;
          font-family: $font-route159;
        }
      }
    }

    .box {
      width: 100%;
      margin: 10px;
      text-align: left;
      position: relative;

      img {
        width: 100%;
        max-width: 580px;
      }
    }
  }

  .article {
    width: 300px;
    min-width: 300px;
    clear: both;
    display: block;
    margin: 0 auto;
    position: relative;
    height: 100%;
  }
}

@media (min-width: $mobile) and (max-width: $tablet - 1) {
  .content4 {
    background: url("../../../../Assets/img/index-bg-02.jpg") bottom center
      no-repeat #020c24;
    padding: 0 0 130px 0;
    width: 100%;
    color: #fff;
    text-align: center;

    .container {
      display: flex;
      width: 100%;
      flex-direction: column-reverse;
      justify-content: space-around;

      h3 {
        font-size: 1.3em;
        padding: 35px;
        text-transform: uppercase;
        font-family: $font-monkyta;
      }

      .box2 {
        z-index: 2;
        width: 100%;
        margin: 0;
        text-align: left;
        position: relative;

        p {
          padding: 0 35px 35px;
          z-index: 50;
          margin: 0 auto;
          word-spacing: 5px;
          position: relative;
          max-width: 800px;
          font-family: $font-route159;
        }
      }
    }

    .box {
      width: 100%;
      margin: 10px;
      text-align: left;
      position: relative;

      img {
        width: 100%;
        max-width: 580px;
      }
    }
  }

  .article {
    width: 450px;
    clear: both;
    display: block;
    margin: 0 auto;
    position: relative;
    height: 100%;
  }
}

@media (min-width: $tablet) and (max-width: $desktop - 1) {
  .content4 {
    background: url("../../../../Assets/img/index-bg-02.jpg") bottom center
      no-repeat #020c24;
    padding: 0 0 80px 0;
    width: 100%;
    color: #fff;
    text-align: center;

    .container {
      display: flex;
      width: 100%;
      flex-direction: column-reverse;
      justify-content: space-around;

      h3 {
        font-size: 2.4em;
        padding: 35px;
        text-transform: uppercase;
        font-family: $font-monkyta;
        text-align: left;
      }

      .box2 {
        z-index: 2;
        width: 100%;
        margin: 0;
        position: relative;

        p {
          padding: 0 35px 35px;
          z-index: 50;
          margin: 0 auto;
          position: relative;
          word-spacing: 5px;
          max-width: 800px;
          font-family: $font-route159;
          text-align: left;
        }
      }
    }

    .box {
      width: 100%;
      margin: 10px;
      text-align: left;
      position: relative;

      img {
        width: 100%;
      }
    }
  }

  .article {
    width: 730px;
    clear: both;
    display: block;
    margin: 0 auto;
    position: relative;
    height: 100%;
  }
}

@media (min-width: $desktop) {
  .content4 {
    background: url("../../../../Assets/img/index-bg-02.jpg") bottom center
      no-repeat #020c24;
    -webkit-mask-image: url('../../../../Assets/img/headPrivacyFlipped.png'), linear-gradient(black, black);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: top, bottom;
    -webkit-mask-size: 100% 12%, 100% 89%;
    mask-image: url('../../../../Assets/img/headPrivacyFlipped.png'), linear-gradient(black, black);
    mask-position: top bottom;
    mask-size: 100% 12%, 100% 89%;
    mask-repeat: no-repeat;
    padding: 80px 0 80px 0;
    width: 100%;
    color: #fff;
    text-align: center;
    position: relative;
    top: -100px;
    margin-bottom: -100px;

    .container {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
      flex-flow: wrap;
      position: relative;
      overflow: hidden;

      h3 {
        font-size: 2.4em;
        padding: 135px 35px 35px 35px;
        text-transform: uppercase;
        font-family: $font-monkyta;
      }

      .box2 {
        z-index: 2;
        width: calc((100% / 2) - 20px);
        margin: 10px;
        text-align: left;
        position: relative;

        p {
          padding: 0px 85px 35px 35px;
          z-index: 50;
          margin: 0 auto;
          word-spacing: 5px;
          position: relative;
          max-width: 800px;
          font-family: $font-route159;
        }
      }
    }

    .box {
      width: calc((100% / 2) - 20px);
      margin: 10px;
      text-align: left;
      position: relative;

      img {
        width: 100%;
        max-width: 580px;
      }
    }
  }

  .article {
    width: 1200px;
    clear: both;
    display: block;
    margin: 0 auto;
    position: relative;
    height: 100%;
  }
}
