@import "../../Styles/colors.scss";
@import "../../Styles/fonts.scss";
$mobile: 576px;
$tablet: 767px;
$laptop: 992px;
$desktop: 1200px;

// Extra mall devices (< 576px)
@media (max-width: $mobile - 1){
  .packContainer {
    width: 100%;
    height: 150vh;
  
    .banner {
      width: 100%;
      height: 275px;
      background-color: $secondary-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-monkyta;
      font-size: 20px;
    }
  
    h4 {
      color: white;
      font-family: $font-nasalization;
      width: 100%;
      text-align: center;
      padding-top: 20px;
      font-size: 20px;
    }
  
    .cardContainer{
      display: flex;
      flex-direction: row;
      justify-content: left;
      gap: 40px;
      padding: 30px 60px;
      width: 100%;
      overflow-x: scroll;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }
};

// Small devices (landscape phones, 576px and up)
@media (min-width: $mobile) and (max-width:$tablet - 1) {
  .packContainer {
    width: 100%;
    height: 150vh;
  
    .banner {
      width: 100%;
      height: 275px;
      background-color: $secondary-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-monkyta;
      font-size: 20px;
    }
  
    h4 {
      color: white;
      font-family: $font-nasalization;
      width: 100%;
      text-align: center;
      padding-top: 20px;
      font-size: 20px;
    }
  
    .cardContainer{
      display: flex;
      flex-direction: row;
      justify-content: left;
      gap: 40px;
      padding: 30px 60px;
      width: 100%;
      overflow-x: scroll;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $tablet) and (max-width: $laptop - 1){
  .packContainer {
    width: 100%;
    height: 150vh;
  
    .banner {
      width: 100%;
      height: 275px;
      background-color: $secondary-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-monkyta;
      font-size: 20px;
    }
  
    h4 {
      color: white;
      font-family: $font-nasalization;
      width: 100%;
      text-align: center;
      padding-top: 20px;
      font-size: 20px;
    }
  
    .cardContainer{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 40px;
      padding: 30px;
      width: 100%;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $laptop) and (max-width: $desktop - 1){
  .packContainer {
    width: 100%;
    height: 150vh;
  
    .banner {
      width: 100%;
      height: 275px;
      background-color: $secondary-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-monkyta;
      font-size: 20px;
    }
  
    h4 {
      color: white;
      font-family: $font-nasalization;
      width: 100%;
      text-align: center;
      padding-top: 20px;
      font-size: 20px;
    }
  
    .cardContainer{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 40px;
      padding: 30px;
      width: 100%;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $desktop){
  .packContainer {
    width: 100%;
    height: 150vh;
  
    .banner {
      width: 100%;
      height: 275px;
      background-color: $secondary-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-monkyta;
      font-size: 20px;
    }
  
    h4 {
      color: white;
      font-family: $font-nasalization;
      width: 100%;
      text-align: center;
      padding-top: 20px;
      font-size: 20px;
    }
  
    .cardContainer{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 40px;
      padding: 30px;
      width: 100%;
    }
  }
}
