@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/breakpoints.scss";

@media (max-width: $mobile - 1) {
    .container {
        display: flex;
        font-family: $font-nasalization;
        color: white;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        padding-bottom: 20px;
        gap: 5px;

        button {
            background: #0049db;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 9px;
            border: none;
            border-radius: 3px;
            cursor: pointer;
        }

        button[disabled] {
            background: #0049db50;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 9px;
            border: none;
            border-radius: 3px;
            cursor: no-drop;

            path {
                fill: rgb(156, 156, 156);
            }
        }

        p {
            padding: 0 6px;
        }

        .input {
            background: none;
            border: 0.5px solid #0049db;
            border-radius: 3px;
            width: 50px;
            padding: 0;
            font-family: $font-nasalization;
            color: white;
            text-align: center;
            height: 28px;
        }

        .inputDisabled {
            background: none;
            border: none;
            width: 50px;
            padding: 0;
            font-family: $font-nasalization;
            color: white;
            text-align: center;
            height: 28px;
        }
    }
}

@media (min-width: $mobile) and (max-width: $tablet - 1) {
    .container {
        display: flex;
        font-family: $font-nasalization;
        color: white;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding-bottom: 20px;
        gap: 5px;

        button {
            background: #0049db;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 9px;
            border: none;
            border-radius: 3px;
            cursor: pointer;
        }

        button[disabled] {
            background: #0049db50;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 9px;
            border: none;
            border-radius: 3px;
            cursor: no-drop;

            path {
                fill: rgb(156, 156, 156);
            }
        }

        p {
            padding: 0 6px;
        }

        .input {
            background: none;
            border: 0.5px solid #0049db;
            border-radius: 3px;
            width: 50px;
            padding: 0;
            font-family: $font-nasalization;
            color: white;
            text-align: center;
            height: 28px;
        }

        .inputDisabled {
            background: none;
            border: none;
            width: 50px;
            padding: 0;
            font-family: $font-nasalization;
            color: white;
            text-align: center;
            height: 28px;
        }
    }
}

@media (min-width: $tablet) and (max-width: $laptop - 1) {
    .container {
        display: flex;
        font-family: $font-nasalization;
        color: white;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding-bottom: 20px;
        gap: 5px;

        button {
            background: #0049db;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 9px;
            border: none;
            border-radius: 3px;
            cursor: pointer;
        }

        button[disabled] {
            background: #0049db50;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 9px;
            border: none;
            border-radius: 3px;
            cursor: no-drop;

            path {
                fill: rgb(156, 156, 156);
            }
        }

        p {
            padding: 0 6px;
        }

        .input {
            background: none;
            border: 0.5px solid #0049db;
            border-radius: 3px;
            width: 50px;
            padding: 0;
            font-family: $font-nasalization;
            color: white;
            text-align: center;
            height: 28px;
        }

        .inputDisabled {
            background: none;
            border: none;
            width: 50px;
            padding: 0;
            font-family: $font-nasalization;
            color: white;
            text-align: center;
            height: 28px;
        }
    }
}

@media (min-width: $laptop) and (max-width: $desktop - 1) {
    .container {
        display: flex;
        font-family: $font-nasalization;
        color: white;
        align-items: center;
        justify-content: center;
        margin-right: 75px;
        margin-top: 10px;
        padding-bottom: 20px;
        gap: 5px;

        button {
            background: #0049db;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 9px;
            border: none;
            border-radius: 3px;
            cursor: pointer;
        }

        button[disabled] {
            background: #0049db50;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 9px;
            border: none;
            border-radius: 3px;
            cursor: no-drop;

            path {
                fill: rgb(156, 156, 156);
            }
        }

        p {
            padding: 0 6px;
        }

        .input {
            background: none;
            border: 0.5px solid #0049db;
            border-radius: 3px;
            width: 50px;
            padding: 0;
            font-family: $font-nasalization;
            color: white;
            text-align: center;
            height: 28px;
        }

        .inputDisabled {
            background: none;
            border: none;
            width: 50px;
            padding: 0;
            font-family: $font-nasalization;
            color: white;
            text-align: center;
            height: 28px;
        }
    }
}

@media (min-width: $desktop) {
    .container {
        display: flex;
        font-family: $font-nasalization;
        color: white;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        padding-bottom: 30px;
        gap: 5px;

        button {
            background: #0049db;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 9px;
            border: none;
            border-radius: 3px;
            cursor: pointer;
        }

        button[disabled] {
            background: #0049db50;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 9px;
            border: none;
            border-radius: 3px;
            cursor: no-drop;

            path {
                fill: rgb(156, 156, 156);
            }
        }

        p {
            padding: 0 6px;
        }

        .input {
            background: none;
            border: 0.5px solid #0049db;
            border-radius: 3px;
            width: 50px;
            padding: 0;
            font-family: $font-nasalization;
            color: white;
            text-align: center;
            height: 28px;
        }

        .inputDisabled {
            background: none;
            border: none;
            width: 50px;
            padding: 0;
            font-family: $font-nasalization;
            color: white;
            text-align: center;
            height: 28px;
        }
    }
}

