@import '../../../Styles/fonts.scss';
$mobile: 576px;
$tablet: 767px;
$laptop: 992px;
$desktop: 1200px;

// Extra mall devices (< 576px)
@media (max-width: $mobile - 1){
    .container{
        display: flex;
        justify-content: left;
        padding-top: 20px;
        gap: 10px;
        align-items: center;
        input {
            border-radius: 3px;
            width: 10px;
        }
        label {
            font-family: $font-nasalization;
            font-size: 10px;
            color: white;
            width: 90%;
        }
        .links{
            text-decoration: none;
            color: rgb(0, 0, 122);
        }
        .link:hover{
            text-decoration: underline;
            color: rgb(0, 0, 122);
            cursor: pointer;
        }
    }
};

// Small devices (landscape phones, 576px and up)
@media (min-width: $mobile) and (max-width:$tablet - 1) {
    .container{
        display: flex;
        justify-content: left;
        padding-top: 20px;
        gap: 10px;
        align-items: center;
        input {
            border-radius: 3px;
            width: 15px;
        }
        label {
            font-family: $font-nasalization;
            font-size: 10px;
            color: white;
            width: 90%;
        }
        .links{
            text-decoration: none;
            color: rgb(0, 0, 122);
        }
        .link:hover{
            text-decoration: underline;
            color: rgb(0, 0, 122);
            cursor: pointer;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $tablet) and (max-width: $laptop - 1){
    .container{
        display: flex;
        justify-content: left;
        padding-top: 20px;
        gap: 10px;
        align-items: center;

        input {
            border-radius: 3px;
            width: 15px;
        }
        label {
            font-family: $font-nasalization;
            font-size: 10px;
            color: white;
            width: 90%;
        }
        .links{
            text-decoration: none;
            color: rgb(0, 0, 122);
        }
        .link:hover{
            text-decoration: underline;
            color: rgb(0, 0, 122);
            cursor: pointer;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: $laptop) and (max-width: $desktop - 1){
    .container{
        display: flex;
        justify-content: left;
        padding-top: 20px;
        gap: 10px;
        align-items: center;

        input {
            border-radius: 3px;
            width: 15px;
        }
        label {
            font-family: $font-nasalization;
            font-size: 15px;
            color: white;
            width: 90%;
        }
        .links{
            text-decoration: none;
            color: rgb(0, 0, 122);
        }
        .link:hover{
            text-decoration: underline;
            color: rgb(0, 0, 122);
            cursor: pointer;
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $desktop){
    .container{
        display: flex;
        justify-content: left;
        padding-top: 20px;
        gap: 10px;
        align-items: center;

        input {
            border-radius: 3px;
            width: 15px;
        }
        label {
            font-family: $font-nasalization;
            font-size: 15px;
            color: white;
            width: 90%;
        }
        .links{
            text-decoration: none;
            color:rgb(0, 0, 122);
        }
        .links:hover{
            text-decoration: underline;
            color: rgb(0, 0, 122);
            cursor: pointer;
        }
    }
}
