@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/breakpoints.scss";

@media (max-width: $laptop - 1) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 2, 36, 0.939);
    background-image: url("../../../../Assets/img/epic_blur.png");
    background-size: cover;
    color: white;
    font-family: $font-monkyta;
    font-weight: normal;
    padding-bottom: 100px;

    .title {
      font-weight: normal;
      margin-top: 50px;
      font-size: 32px;
    }

    .months {
      display: none;
    }

    .q {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      width: 75%;
      max-width: 500px;
    }

    .boxMobile {
      margin: 10px;
      background: #081f9180;
      border: 6px solid #0149db;
      box-shadow: 0px 0px 15px 0px #a427e340 inset;
      box-shadow: 0px 0px 20px 0px #a427e380;
      border-radius: 16px;

      h3 {
        font-weight: normal;
        text-align: center;
        margin-top: 5px;
      }

      ul {
        padding: 10px 0;
        text-align: center;

        li {
          list-style: inside;
        }
      }
    }

    .q1 {
      @extend .boxMobile;
      grid-column-start: 1;
      grid-row-start: 1;
    }

    .q2 {
      @extend .boxMobile;
      grid-column-start: 2;
      grid-row-start: 2;
    }

    .q3 {
      @extend .boxMobile;
      grid-column-start: 3;
      grid-row-start: 3;
    }

    .q4 {
      @extend .boxMobile;
      grid-column-start: 4;
      grid-row-start: 4;
    }
  }
}

@media (min-width: $laptop) and (max-width: $desktop - 1) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 2, 36, 0.939);
    background-image: url("../../../../Assets/img/epic_blur.png");
    background-size: cover;
    color: white;
    font-family: $font-monkyta;
    font-weight: normal;
    padding-bottom: 100px;

    .title {
      font-weight: normal;
      margin-top: 50px;
    }

    .months {
      margin-top: 25px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(12, auto);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      justify-items: center;
    }

    .q {
      margin-top: 30px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-template-rows: repeat(min(4), auto);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    }

    .box {
      margin: 10px;
      background: #081f9180;
      border: 6px solid #0149db;
      box-shadow: 0px 0px 15px 0px #a427e340 inset;
      box-shadow: 0px 0px 20px 0px #a427e380;
      border-radius: 16px;

      h3 {
        font-weight: normal;
        text-align: center;
        margin-top: 5px;
      }

      ul {
        padding: 10px 0;
        text-align: center;

        li {
          list-style: inside;
        }
      }
    }

    .q1 {
      @extend .box;
      grid-column-start: 1;
      grid-row-start: 1;
    }

    .q2 {
      @extend .box;
      grid-column-start: 2;
      grid-row-start: 2;
    }

    .q3 {
      @extend .box;
      grid-column-start: 3;
      grid-row-start: 3;
    }

    .q4 {
      @extend .box;
      grid-column-start: 4;
      grid-row-start: 4;
    }
  }
}

@media (min-width: $desktop) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 2, 36, 0.939);
    background-image: url("../../../../Assets/img/epic_blur.png");
    background-size: cover;
    color: white;
    font-family: $font-monkyta;
    font-weight: normal;
    padding-bottom: 100px;

    .title {
      font-weight: normal;
      margin-top: 50px;
      font-size: 32px;
    }

    .months {
      margin-top: 25px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(12, auto);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      justify-items: center;
    }

    .q {
      margin-top: 30px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-template-rows: repeat(min(4), auto);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    }

    .boxHD {
      margin: 10px;
      height: 140px;
      background: #081f9180;
      border: 6px solid #0149db;
      box-shadow: 0px 0px 15px 0px #a427e340 inset;
      box-shadow: 0px 0px 20px 0px #a427e380;
      border-radius: 16px;

      h3 {
        font-weight: normal;
        text-align: center;
        margin-top: 5px;
      }

      ul {
        padding: 10px 0;
        text-align: center;

        li {
          list-style: inside;
        }
      }
    }

    .q1 {
      @extend .boxHD;
      grid-column-start: 1;
      grid-row-start: 1;
    }

    .q2 {
      @extend .boxHD;
      grid-column-start: 2;
      grid-row-start: 2;
    }

    .q3 {
      @extend .boxHD;
      grid-column-start: 3;
      grid-row-start: 3;
    }

    .q4 {
      @extend .boxHD;
      grid-column-start: 4;
      grid-row-start: 4;
    }
  }
}
