@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/breakpoints.scss";

@media (max-width: $mobile - 1) {
  .content6 {
    background: url("../../../../Assets/img/index-bg-04.jpg") bottom center
      no-repeat #020c24;
    padding: 0 0 160px 0;
    width: 100%;
    color: #fff;
    text-align: center;
  }

  .article {
    width: 300px;
    min-width: 300px;
    clear: both;
    display: block;
    margin: 0 auto;
    position: relative;
    height: 100%;
  }

  .section {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: space-around;
    height: 100%;
    overflow: hidden;
  }

  .box2 {
    z-index: 2;
    width: 100%;
    margin: 0;
    text-align: left;
    position: relative;

    img {
      margin-top: -40px;
      width: 100%;
      border: 0;
    }

    h3 {
      font-size: 1.3em;
      padding: 35px;
      text-transform: uppercase;
      font-family: $font-monkyta;
    }

    p {
      padding: 0 35px 35px;
      z-index: 99;
      margin: 0 auto;
      position: relative;
      max-width: 800px;
      font-family: $font-route159;
    }
  }
}

@media (min-width: $mobile) and (max-width: $tablet - 1) {
  .content6 {
    background: url("../../../../Assets/img/index-bg-04.jpg") bottom center
      no-repeat #020c24;
    padding: 0 0 160px 0;
    width: 100%;
    color: #fff;
    text-align: center;
  }

  .article {
    width: 450px;
    clear: both;
    display: block;
    margin: 0 auto;
    position: relative;
    height: 100%;
  }

  .section {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: space-around;
    height: 100%;
  }

  .box2 {
    z-index: 2;
    width: 100%;
    margin: 0;
    text-align: left;
    position: relative;

    img {
      margin-top: -40px;
      width: 100%;
      border: 0;
    }

    h3 {
      font-size: 1.3em;
      padding: 35px;
      text-transform: uppercase;
      font-family: $font-monkyta;
    }

    p {
      padding: 0 35px 35px;
      z-index: 99;
      margin: 0 auto;
      position: relative;
      max-width: 800px;
      font-family: $font-route159;
    }
  }
}

@media (min-width: $tablet) and (max-width: $desktop - 1) {
  .content6 {
    background: url("../../../../Assets/img/index-bg-04.jpg") bottom center
      no-repeat #020c24;
    padding: 0 0 110px 0;
    width: 100%;
    color: #fff;
    text-align: center;
  }

  .article {
    width: 730px;
    clear: both;
    display: block;
    margin: 0 auto;
    position: relative;
    height: 100%;
  }

  .section {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: space-around;
    height: 100%;
  }

  .box2 {
    z-index: 2;
    width: 100%;
    margin: 0;
    text-align: left;
    position: relative;

    img {
      margin-top: -40px;
      width: 100%;
      border: 0;
    }

    h3 {
      font-size: 2.4em;
      padding: 35px;
      text-transform: uppercase;
      font-family: $font-monkyta;
    }

    p {
      padding: 0 35px 35px;
      z-index: 99;
      margin: 0 auto;
      position: relative;
      max-width: 800px;
      font-family: $font-route159;
    }
  }
}

@media (min-width: $desktop) {
  .content6 {
    background: url("../../../../Assets/img/index-bg-04.jpg") bottom center
      no-repeat #020c24;
    padding: 0 0 110px 0;
    width: 100%;
    color: #fff;
    text-align: center;
  }

  .article {
    width: 1200px;
    clear: both;
    display: block;
    margin: 0 auto;
    position: relative;
    height: 100%;
  }

  .section {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
    overflow: hidden;
  }

  .box2 {
    z-index: 2;
    width: calc((100% / 2) - 20px);
    margin: 10px;
    text-align: left;
    position: relative;

    img {
      margin-top: -40px;
      width: 100%;
      border: 0;
    }

    h3 {
      font-size: 2.4em;
      padding: 105px 35px 35px 35px;
      text-transform: uppercase;
      font-family: $font-monkyta;
    }

    p {
      padding: 0 35px 35px;
      z-index: 99;
      margin: 0 auto;
      position: relative;
      max-width: 800px;
      font-family: $font-route159;
    }
  }
}
