@import "../../Styles/fonts.scss";
@import "../../Styles/breakpoints.scss";

@media (max-width: $tablet - 1) {
    .container {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h3 {
            font-family: $font-nasalization;
            color: white;
            font-size: 95px;
            text-align: center;
        }

        h3:hover {
            transform: rotate(1deg);
            transition-duration: 0.3s;
        }

        p {
            font-family: $font-nasalization;
            color: white;
            font-size: 18px;
            text-align: center;
            margin-top: 10px;
        }

        button {
            margin-top: 35px;
        }

        .deg {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        svg {
            margin-bottom: -25px;
            width: 50px;
        }
    }
}

@media (min-width: $tablet) {
    .container {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h3 {
            font-family: $font-nasalization;
            color: white;
            font-size: 130px;
            text-align: center;
            font-weight: bolder;
        }

        h3:hover {
            transform: rotate(1deg);
            transition-duration: 0.3s;
        }

        p {
            font-family: $font-nasalization;
            color: white;
            font-size: 25px;
            font-weight: 400;
            text-align: center;
        }

        button {
            margin-top: 35px;
        }

        .deg {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 150px;
            padding-top: 60px;
            border-image: linear-gradient(180deg, #0146d5 0%, rgba(1, 70, 213, 0) 100%) 1;
            border-top: 2px solid;
            border-bottom: none;
            border-right: 2.1px solid;
            border-left: 2.3px solid;
            background: linear-gradient(180deg, rgba(4, 18, 55, 0.65) 0%, rgba(4, 18, 55, 0) 100%);
        }

        svg {
            margin-bottom: -30px;
            width: 55px;
        }
    }
}
