@import "../../../../Styles/colors.scss";
@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/breakpoints.scss";

.container {
  margin: 0 auto;
  width: 90vw;
  max-width: 800px;
  z-index: 1;
  border: 1.5px solid;
  border-image: linear-gradient(180deg, #0146d5 0%, rgba(1, 70, 213, 0) 100%) 1;
  border-bottom: none;
  font-family: $font-nasalization;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(4 18 55 / 70%) 10%,
    rgb(4 18 55) 100%
  );
  padding: 15px 0;

  .info {
    font-size: 14px;
    font-weight: bold;
    color: white;
    font-family: $font-montHeavy;
  }

  h4 {
    text-align: center;
    margin-top: 10px;
  }

  p {
    width: 100%;
    text-align: center;
  }
}

.title {
  color: $account-name;
  font-weight: 400;
  font-size: 25px;
}

.joined {
  margin-top: 5px;
  color: white;
  font-size: 10px;
  font-weight: 400;
}

.linked {
  font-family: $font-montHeavy;
  color: white;
  font-size: 15px;
  padding: 15px 0;
}

.nCoin {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 10px;
  font-size: 15px;

  span {
    color: $go-back;
    font-family: $font-nanum;
    font-weight: bold;
  }
}

.img {
  width: 18px;
}
