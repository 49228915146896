$nav-back: rgba(1, 3, 32, 0.90);
$primary-blue: #081f91;
$secondary-blue: #0049db;
$deep-blue: #002b80;
$login-button: #212739;
$signUp-button: #051237;
$input-background: rgba(4, 18, 55, 0.21);
$blue-dialog-background: rgba(1, 73, 219, 0.85);
$account-bg: rgba(24, 146, 240, 0.12);
$account-border: #0149db;
$account-name: #ffdd00;
$sub-message: #6b84c8;
$logout: #1892f0;
$card-bg: #030b23;
$go-back: #3d91ef;
$glow-modal: #3376ff;
$glow-modal-light: #7da5f7;
$new-bg: #1b3684;
$market-footer: #242339;
$damage: #FF2D00;
$damage-dark: #941A00;
$energy: #49FF00;
$energy-dark: #288C00;
$cooltime: #FFF700;
$cooltime-dark: #A49E00;

