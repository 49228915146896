@import "../../../../Styles/breakpoints.scss";
@import "../../../../Styles/colors.scss";

@media (max-width: $mobile - 1) {
  .socialMedia {
    position: fixed;
    top: 50%;
    margin-left: 80%;
    width: 15%;
    display: flex;
    flex-direction: column;
    padding: 0;
    z-index: 10;
    height: 300px;
    /*  */
    display: none;
    /*  */

  }
}

@media (min-width: $mobile) and (max-width: $tablet - 1) {
  .socialMedia {
    position: fixed;
    top: 63%;
    margin-left: 90%;
    width: 10%;
    display: flex;
    flex-direction: column;
    padding: 0;
    z-index: 10;
    height: 300px;
    gap: 30px;
    overflow: visible;
  
      div {
        padding: 4px;
        height: 35px;
        width: 35px;
        border-radius: 35px;
        position: relative;
        z-index: 1;
        overflow-x: visible;

        img {
          transform: scale(3);
          width: 100%;
        }
  
        &:before {
          z-index: -1;
          content: '';
          background: linear-gradient(90deg, $account-border, $glow-modal, $account-border);
          position: absolute;
          top: -5px;
          left: -5px;
          background-size: 500%;
          filter: blur(3px);
          width: calc(100% + 10px);
          height: calc(100% + 10px);
          animation: glowing 10s linear infinite;
          opacity: 0;
          transition: opacity .3s ease-in-out;
          border-radius: 30px;
        }
        &:hover:before {
          opacity: 1;
        }
  
        &:after{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: transparent;
          left: 0px;
          top: 0px;
          border-radius: 25px;
          z-index: -1;
        }
  
        @keyframes glowing {
          0% { background-position: 0 0; }
          100% { background-position: 500% 0; }
        } 
      }   
    }
}

@media (min-width: $tablet) and (max-width: $desktop - 1) {
  .socialMedia {
    position: fixed;
    top: 63%;
    margin-left: 92%;
    width: 7%;
    display: flex;
    flex-direction: column;
    padding: 0;
    z-index: 10;
    height: 300px;
    gap: 30px;
    overflow: visible;
  
      div {
        padding: 4px;
        height: 35px;
        width: 35px;
        border-radius: 35px;
        position: relative;
        z-index: 1;
        overflow-x: visible;

        img {
          transform: scale(3);
          width: 100%;
        }
  
        &:before {
          z-index: -1;
          content: '';
          background: linear-gradient(90deg, $account-border, $glow-modal, $account-border);
          position: absolute;
          top: -5px;
          left: -5px;
          background-size: 500%;
          filter: blur(4px);
          width: calc(100% + 10px);
          height: calc(100% + 10px);
          animation: glowing 10s linear infinite;
          opacity: 0;
          transition: opacity .3s ease-in-out;
          border-radius: 30px;
        }
        &:hover:before {
          opacity: 1;
        }
  
        &:after{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: transparent;
          left: 0px;
          top: 0px;
          border-radius: 25px;
          z-index: -1;
        }
  
        @keyframes glowing {
          0% { background-position: 0 0; }
          100% { background-position: 500% 0; }
        } 
      }   
    }
}

@media (min-width: $desktop) {
  .socialMedia {
    position: fixed;
    top: 66%;
    margin-left: 91%;
    width: 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    z-index: 10;
    height: 300px;
    gap: 30px;
    overflow: visible;
  
      div {
        padding: 4px;
        height: 35px;
        width: 35px;
        border-radius: 35px;
        position: relative;
        z-index: 1;
        overflow-x: visible;

        img {
          transform: scale(3);
          width: 100%;
        }
  
        &:before {
          z-index: -1;
          content: '';
          background: linear-gradient(90deg, $account-border, $glow-modal, $account-border);
          position: absolute;
          top: -5px;
          left: -5px;
          background-size: 500%;
          filter: blur(3px);
          width: calc(100% + 10px);
          height: calc(100% + 10px);
          animation: glowing 10s linear infinite;
          opacity: 0;
          transition: opacity .3s ease-in-out;
          border-radius: 30px;
        }
        &:hover:before {
          opacity: 1;
        }
  
        &:after{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: transparent;
          left: 0px;
          top: 0px;
          border-radius: 25px;
          z-index: -1;
        }
  
        @keyframes glowing {
          0% { background-position: 0 0; }
          100% { background-position: 500% 0; }
        } 
      }   
    }
}