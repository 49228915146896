$mobile: 576px;
$tablet: 767px;
$laptop: 992px;
$desktop: 1200px;
$hd: 1400px;

@media (max-width: $tablet - 1){
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
    }
    
    .cardDeck {
        display: flex;
        flex-direction: row;
        justify-content: left;
        width: 100%;
        padding: 30px 20px;
        gap: 220px;
        width: 100%;
        overflow-x: scroll;
    }
  
    // ::-webkit-scrollbar {
    //     display: none;
    // }
    
    .buttonContainer {
        padding: 20px 0px;
        display: flex;
    }
};

@media (min-width: $tablet) and (max-width:$desktop - 1) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
    }
    
    .cardDeck {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 60vh;
        width: 100%;
        gap: 50px;
    }
    
    .buttonContainer {
        display: flex;
        padding: 30px 0px;
    }
}

@media (min-width: $desktop) and (max-width: $hd - 1){

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 40px;
        align-items: center;
        width: 100%;
        height: 100vh;
    }
    
    .cardDeck {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 50vh;
        width: 100%;
        gap: 50px;
    }
    
    .buttonContainer {
        display: flex;
    }
}

@media (min-width: $hd){
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 50px;
        align-items: center;
        width: 100%;
        height: 100vh;
    }
    
    .cardDeck {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 40vh;
        width: 100%;
        gap: 50px;
    }
    
    .buttonContainer {
        display: flex;
    }
}