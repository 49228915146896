@import "../../../Styles/colors.scss";
@import "../../../Styles/fonts.scss";
$mobile: 576px;
$tablet: 767px;
$laptop: 992px;
$desktop: 1200px;
$hd: 1400px;


// Extra mall devices (< 576px)
@media (max-width: $mobile - 1){
    .text{
        font-family: $font-nasalization;
        font-weight: normal;
        font-size: 11px;
        color: #ffff;
    }

    .check{
        border-radius: 3px;
        width: 15px;
    }

    .container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 80px;
    }
    .question{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 70%;
        padding-top: 20px;
    }
    .checkBoxes{
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 30px;
        padding-left: 10px;
        gap: 20px;
        .inLine{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: $mobile) and (max-width:$tablet - 1) {
    .text{
        font-family: $font-nasalization;
        font-weight: normal;
        font-size: 11px;
        color: #ffff;
    }

    .check{
        border-radius: 3px;
        width: 15px;
    }

    .container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 80px;
    }
    .question{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 70%;
        padding-top: 10px;
    }
    .checkBoxes{
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 30px;
        padding-left: 10px;
        gap: 20px;
        .inLine{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $tablet) and (max-width: $laptop - 1){
    .text{
        font-family: $font-nasalization;
        font-weight: normal;
        font-size: 13px;
        color: #ffff;
    }

    .check{
        border-radius: 3px;
        width: 15px;
    }

    .container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100px;
    }
    .question{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 80%;
    }
    .checkBoxes{
        width: 20%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 30px;
        padding-left: 10px;
        gap: 20px;
        .inLine{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: $laptop) and (max-width: $desktop - 1){
    .text{
        font-family: $font-nasalization;
        font-weight: normal;
        font-size: 13px;
        color: #ffff;
    }

    .check{
        border-radius: 3px;
        width: 15px;
    }

    .container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100px;
    }
    .question{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 80%;
    }
    .checkBoxes{
        width: 20%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 30px;
        padding-left: 10px;
        gap: 20px;
        .inLine{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $desktop) and (max-width: $hd - 1){

    .text{
        font-family: $font-nasalization;
        font-weight: normal;
        font-size: 13px;
        color: #ffff;
    }

    .check{
        border-radius: 3px;
        width: 15px;
    }

    .container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100px;
    }
    .question{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 80%;
    }
    .checkBoxes{
        width: 20%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 30px;
        padding-left: 10px;
        gap: 20px;
        .inLine{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $hd){
    .text{
        font-family: $font-nasalization;
        font-weight: normal;
        font-size: 13px;
        color: #ffff;
    }

    .check{
        border-radius: 3px;
        width: 15px;
    }

    .container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100px;
    }
    .question{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 80%;
    }
    .checkBoxes{
        width: 20%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 30px;
        padding-left: 10px;
        gap: 20px;
        .inLine{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
        }
    }
}