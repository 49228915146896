@import "../../Styles/colors.scss";

.deg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position: absolute;
  padding-top: 80px;
  background: linear-gradient(180deg, rgba(2, 34, 109, 0) 0%, #02226d 43.73%);
}

.container {
  background: linear-gradient(180deg, rgb(4, 15, 44) 0%, rgba(4, 18, 55, 0.111) 100%);
  width: 100%;
  height: 100vh;
  background-color: $new-bg;
  position: absolute;
  top: 0;
  z-index: 0;

  ::-webkit-scrollbar {
    display: none;
  }
}

.image {
  background: linear-gradient(180deg, rgba(2, 34, 109, 0) 0%, #02226d 43.73%);
  background-image: url("../../Assets/img/BackgroundAccount.png");
  z-index: 1;
  height: 100vh;
  opacity: 1;
  width: 100%;
  padding-top: 80px;
  background-repeat: round;
}
