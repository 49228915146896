@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/colors.scss";
@import "../../../../Styles/breakpoints.scss";

@media (max-width: $tablet - 1) {
  .parentContainer {
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;

    .orderByContainer {
      width: calc(34vw - 60px);
      height: 40px;
      background-color: $primary-blue;
      font-family: $font-nasalization;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .filtersContainer {
      position: absolute;
      top: 40px;
      left: 0;
      width: calc(34vw - 60px);
      height: fit-content;
      background-color: $primary-blue;
      font-family: $font-nasalization;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (min-width: $tablet) and (max-width: $desktop - 1) {
    .parentContainer {
        position: relative;
        overflow: visible;
        display: flex;
        flex-direction: column;
    
        .orderByContainer {
          color: white;
          width: 100%;
          font-family: $font-nasalization;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          border-radius: 10px;
    
          .title {
            background-color: $account-border;
            height: 40px;
            padding: 0 15px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            h2 {
              font-size: 15px;
            }
    
            h3 {
              cursor: pointer;
              font-size: 20px;
            }
          }
        }
    
        .filtersContainer {
          padding: 15px 0;
          width: 100%;
          height: fit-content;
          background-color: #1892f01f;
          font-family: $font-nasalization;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 5px;
        }
      }
}

@media (min-width: $desktop) {
  .parentContainer {
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;

    .orderByContainer {
      color: white;
      width: 100%;
      font-family: $font-nasalization;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 10px;

      .title {
        background-color: $account-border;
        height: 40px;
        padding: 0 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          font-size: 15px;
        }

        h3 {
          cursor: pointer;
          font-size: 20px;
        }
      }
    }

    .filtersContainer {
      padding: 15px 0;
      width: 100%;
      height: fit-content;
      background-color: #1892f01f;
      font-family: $font-nasalization;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 5px;
    }
  }
}
