@import "../../../../../Styles/fonts.scss";
@import "../../../../../Styles/colors.scss";
@import "../../../../../Styles/breakpoints.scss";

@media (min-width: $desktop) {
  .filter {
    display: flex;
    align-items: center;
    margin-left: 35px;
    gap: 10px;
    font-size: 13px;
  }

  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background-color: #ffffff;
    font-family: inherit;
    color: #0149db85;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid #0149db;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  input[type="checkbox"]:checked:after {
    content: "X";
    color: #000000;
    text-align: center;
    left: 50%;
    top: 50%;
    font-size: 10px;
    transition-duration: 0.3s;
    font-family: $font-nasalization;
  }

  .btnFilter {
    z-index: 2;
    height: 40px;
    background: #0149db85;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 2px solid #0149db;
    cursor: pointer;

    svg {
      width: 22px;
    }
  }
}

@media (max-width: $desktop - 1) {
  .filter {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 13px;
    font-family: $font-nasalization;
    color: white;
  }

  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background-color: #ffffff;
    font-family: inherit;
    color: #0149db85;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid #0149db;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  input[type="checkbox"]:checked:after {
    content: "X";
    color: #000000;
    text-align: center;
    left: 50%;
    top: 50%;
    font-size: 10px;
    transition-duration: 0.3s;
    font-family: $font-nasalization;
  }
}
