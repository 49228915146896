@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/breakpoints.scss";

@media (max-width: $mobile - 1) {
  .content6 {
    background: url("../../../../Assets/img/index-bg-05.jpg") center bottom
      no-repeat rgb(218, 61, 229);
    padding: 0 0 130px 0;
    width: 100%;
    color: #fff;
    text-align: center;

    article {
      width: 300px;
      min-width: 300px;
      clear: both;
      display: block;
      margin: 0 auto;
      position: relative;
      height: 100%;

      section {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;

        div {
          z-index: 2;
          width: 100%;
          margin: 0;
          text-align: left;
          position: relative;

          h3 {
            font-size: 1.3em;
            padding: 35px;
            text-transform: uppercase;
            font-family: $font-monkyta;
          }

          p {
            padding: 0 35px 35px;
            z-index: 99;
            margin: 0 auto;
            position: relative;
            max-width: 800px;
            font-size: 1em;
            font-family: $font-route159;
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: $mobile) and (max-width: $tablet - 1) {
  .content6 {
    background: url("../../../../Assets/img/index-bg-05.jpg") center bottom
      no-repeat rgb(218, 61, 229);
    padding: 0 0 130px 0;
    width: 100%;
    color: #fff;
    text-align: center;

    article {
      width: 450px;
      clear: both;
      display: block;
      margin: 0 auto;
      position: relative;
      height: 100%;

      section {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;

        div {
          z-index: 2;
          width: 100%;
          margin: 0;
          text-align: left;
          position: relative;

          h3 {
            font-size: 1.3em;
            padding: 35px;
            text-transform: uppercase;
            font-family: $font-monkyta;
          }

          p {
            padding: 0 35px 35px;
            z-index: 99;
            margin: 0 auto;
            position: relative;
            max-width: 800px;
            font-size: 1em;
            font-family: $font-route159;
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: $tablet) and (max-width: $desktop - 1) {
  .content6 {
    background: url("../../../../Assets/img/index-bg-05.jpg") center bottom
      no-repeat rgb(218, 61, 229);
    padding: 0px 0px 110px;
    width: 100%;
    color: #fff;
    text-align: center;

    article {
      width: 730px;
      clear: both;
      display: block;
      margin: 0 auto;
      position: relative;
      height: 100%;

      section {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;

        div {
          z-index: 2;
          width: 100%;
          margin: 0;
          text-align: left;
          position: relative;

          h3 {
            font-size: 2.4em;
            padding: 35px;
            text-transform: uppercase;
            font-family: $font-monkyta;
          }

          p {
            padding: 0 35px 35px;
            z-index: 99;
            margin: 0 auto;
            position: relative;
            max-width: 800px;
            font-size: 1em;
            font-family: $font-route159;
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: $desktop) {
  .content6 {
    background: url("../../../../Assets/img/index-bg-05.jpg") center bottom
      no-repeat rgb(218, 61, 229);
    padding: 0px 0px 110px;
    width: 100%;
    color: #fff;
    text-align: center;

    article {
      width: 1200px;
      clear: both;
      display: block;
      margin: 0 auto;
      position: relative;
      height: 100%;

      section {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        flex-flow: wrap;
        overflow: hidden;

        div {
          z-index: 2;
          width: calc((100% / 2) - 20px);
          margin: 10px;
          text-align: left;
          position: relative;

          h3 {
            font-size: 2.4em;
            padding: 105px 35px 35px 35px;
            text-transform: uppercase;
            font-family: $font-monkyta;
          }

          p {
            padding: 0 35px 35px;
            z-index: 99;
            margin: 0 auto;
            position: relative;
            max-width: 800px;
            font-size: 1em;
            font-family: $font-route159;
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }
}
