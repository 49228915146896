@import "../../../../Styles/colors.scss";
@import "../../../../Styles/breakpoints.scss";
@import "../../../../Styles/fonts.scss";

@media (max-width: $desktop - 1) {
  .cardsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    a {
      width: 0;
    }

    .cards {
      margin-top: 30px;
      justify-content: left;
      padding: 0px 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      overflow: visible;
      gap: 30px;
    }
  }
}

@media (min-width: $desktop) and (max-width: $HD - 1) {
  .cardsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    a {
      width: 0;
    }

    .cards {
      margin-top: 30px;
      padding: 0px 30px;
      display: grid;
      justify-content: space-evenly;
      grid-template-columns: repeat(4, 0fr);
      grid-column-gap: 75px;
      grid-row-gap: 30px;
      overflow: visible;
    }
  }
}

@media (min-width: $HD) {
  .cardsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    a {
      width: 0;
    }

    .cards {
      margin-top: 30px;
      padding: 0px 30px;
      display: grid;
      justify-content: space-evenly;
      grid-template-columns: repeat(5, 0fr);
      grid-column-gap: 75px;
      grid-row-gap: 30px;
      overflow: visible;
    }
  }
}
