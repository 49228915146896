@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/colors.scss";
@import "../../../../Styles/breakpoints.scss";

@media (max-width: $mobile - 1){
    .listingMobileContainer {
        top: 0;
        position: absolute;
        width: 100vw;
        height: 100vh;
        padding-top: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-nasalization;
        color: white;
        font-weight: 500;
        text-align: center;

        h3 {
            position: absolute;
            top: 30px;
            font-size: 13px;
        }

        .container {
            width: 100%;
            margin-top: 25px;

            .top:first-child {
                display: flex;
                gap: 30px;
                align-items: center;
                justify-content: center;
                margin-top: 20px;

                .infoContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .title {
                        font-weight: 500;
                    }

                    .info {
                        border-radius: 5px;
                        background: #1892f070;
                        border: 2px solid #ffffff33;
                        padding: 10px 25px;
                        font-size: 13px;
                    }
                }
            }

            .top {
                display: flex;
                gap: 30px;
                align-items: center;
                justify-content: center;
                margin-top: 30px;

                .infoContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .title {
                        font-weight: 500;
                    }

                    .info {
                        border-radius: 5px;
                        background: #1892f070;
                        border: 2px solid #ffffff33;
                        padding: 10px 25px;
                        font-size: 13px;
                    }
                }
            }

            .seller {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                gap: 5px;
                align-items: center;
                justify-content: center;

                .title {
                    font-weight: 500;
                }

                .info {
                    border-radius: 5px;
                    background: #1892f070;
                    border: 2px solid #ffffff33;
                    padding: 10px 0px;
                    width: 85%;
                    font-size: 13px;
                }
            }

            button {
                text-align: center;
                padding: 10px 0px;
                width: 85%;
                border: none;
                margin-top: 20px;
                border-radius: 5px;
                background-color: $primary-blue;
                color: white;
                font-family: $font-nasalization;
                cursor: pointer;
            }

            hr {
                width: 50%;
                margin: 0 auto;
                margin-top: 10px;
            }
        }
    }
}

@media (min-width: $mobile) and (max-width: $tablet - 1) {
    .listingMobileContainer {
        top: 0;
        position: absolute;
        width: 100vw;
        height: 100vh;
        padding-top: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-nasalization;
        color: white;
        font-weight: 500;
        text-align: center;

        h3 {
            position: absolute;
            top: 25px;
        }

        .container {
            width: 100%;
            margin-top: 25px;

            .top:first-child {
                display: flex;
                gap: 30px;
                align-items: center;
                justify-content: center;
                margin-top: 20px;

                .infoContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .title {
                        font-weight: 500;
                    }

                    .info {
                        border-radius: 5px;
                        background: #1892f070;
                        border: 2px solid #ffffff33;
                        padding: 10px 35px;
                        font-size: 13px;
                    }
                }
            }

            .top {
                display: flex;
                gap: 30px;
                align-items: center;
                justify-content: center;
                margin-top: 30px;

                .infoContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .title {
                        font-weight: 500;
                    }

                    .info {
                        border-radius: 5px;
                        background: #1892f070;
                        border: 2px solid #ffffff33;
                        padding: 10px 35px;
                        font-size: 13px;
                    }
                }
            }

            .seller {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                gap: 5px;
                align-items: center;
                justify-content: center;

                .title {
                    font-weight: 500;
                }

                .info {
                    border-radius: 5px;
                    background: #1892f070;
                    border: 2px solid #ffffff33;
                    padding: 10px 0px;
                    width: 50%;
                    font-size: 13px;
                }
            }

            button {
                text-align: center;
                padding: 10px 0px;
                width: 50%;
                border: none;
                margin-top: 20px;
                border-radius: 5px;
                background-color: $primary-blue;
                color: white;
                font-family: $font-nasalization;
                cursor: pointer;
            }

            hr {
                width: 30%;
                margin: 0 auto;
                margin-top: 10px;
            }
        }
    }
}
