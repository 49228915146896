.container {
  background-color: rgba(0, 2, 36, 0.939);
  background-image: url("../../../../Assets/img/epic_blur.png");
  background-size: cover;
  padding: 50px 5px;

  button {
    background-color: #081f91 !important;
    box-shadow: 0 0 1px 3px #05197e;
    color: white !important;
  }

  button:hover {
    background-color: #081f91 !important;
  }
}

.btnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}
