@import './fonts.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    overflow-x: hidden;
    scrollbar-width: none;
    .grecaptcha-badge { 
        visibility: hidden;
    }
    //Custom font for sweet alert2
    .swal2-html-container {
        font-family: $font-nasalization;
    }
    .swal2-confirm{
        font-family: $font-nasalization;
    }
}