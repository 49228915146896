@import "../../../../Styles/colors.scss";
@import "../../../../Styles/fonts.scss";

$mobile: 576px;
$tablet: 767px;
$laptop: 992px;
$desktop: 1200px;
$hd: 1400px;

// Extra mall devices (< 576px)
@media (max-width: $laptop - 1) {

  .parentContainerModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .textDrop {
    font-size: 14px;
    color: white;
    font-family: $font-nasalization;
    padding: 30px 5px;
    text-align: center;
  }

  .buttonsContainer{
    padding: 12px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    button {
        padding: 5px;
        width: 135px;
    }
  }
}

@media (min-width: $laptop) and (max-width: $hd - 1) {
  .parentContainerModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .textDrop {
    font-size: 20px;
    color: white;
    font-family: $font-nasalization;
    padding: 30px 20px;
    text-align: center;
  }

  .buttonsContainer{
    padding: 12px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    button {
        width: 176px;
    }
    }
}

@media (min-width: $hd) {

  .parentContainerModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .textDrop {
    font-size: 20px;
    color: white;
    font-family: $font-nasalization;
    padding: 30px 20px;
    text-align: center;
  }


  .buttonsContainer{
    padding: 12px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    button {
        width: 176px;
    }
    }
}