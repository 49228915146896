@import '../../Styles/colors.scss';
@import '../../Styles/fonts.scss';

$mobile: 576px;
$tablet: 767px;
$laptop: 992px;
$desktop: 1200px;

// Extra mall devices (< 576px)
@media (max-width: $mobile - 1){

    .container{
        cursor: pointer;
        overflow: visible;
        position: relative;
        background-color: $card-bg;
        padding-bottom: 18px;
        padding-top: 48px;
        height: 410px;
        width: 280px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $font-nasalization;
    }

    .imagenCard {
        margin-bottom: 12px;
        height: 244px;
        width: 244px;
    }

    .texto {
        font-size: 20px;
        margin-top: 6px;
    }

    .sale{
        background-color: $secondary-blue;
        width: 53px;
        height: 53px;
        border-radius: 50px;
        font-family: $font-nasalization;
        font-size: 15px;
        color: white;
        position: absolute;
        top: -10px;
        right: -10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .soldOut{
        cursor: pointer;
        overflow: visible;
        position: relative;
        background-color: $card-bg;
        padding-bottom: 18px;
        padding-top: 48px;
        height: 410px;
        width: 280px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $font-nasalization;
        opacity: 0.6;
    }
    
    .textSoldOut{
        font-size: 40px;
        color: $secondary-blue;
        transform: rotate(-30deg);
        text-align: "center";
        position: absolute;
        top: 160px;
        right: 30px;
        font-weight: bold;
    }
};

// Small devices (landscape phones, 576px and up)
@media (min-width: $mobile) and (max-width:$tablet - 1) {
    .container{
        cursor: pointer;
        overflow: visible;
        position: relative;
        background-color: $card-bg;
        padding-bottom: 18px;
        padding-top: 48px;
        height: 410px;
        width: 280px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $font-nasalization;
    }

    .imagenCard {
        margin-bottom: 12px;
        height: 244px;
        width: 244px;
    }

    .texto {
        font-size: 20px;
        margin-top: 6px;
    }

    .sale{
        background-color: $secondary-blue;
        width: 53px;
        height: 53px;
        border-radius: 50px;
        font-family: $font-nasalization;
        font-size: 15px;
        color: white;
        position: absolute;
        top: -10px;
        right: -10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .soldOut{
        cursor: pointer;
        overflow: visible;
        position: relative;
        background-color: $card-bg;
        padding-bottom: 18px;
        padding-top: 48px;
        height: 410px;
        width: 280px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $font-nasalization;
        opacity: 0.6;
    }
    
    .textSoldOut{
        font-size: 40px;
        color: $secondary-blue;
        transform: rotate(-30deg);
        text-align: "center";
        position: absolute;
        top: 160px;
        right: 30px;
        font-weight: bold;
    }
};

// Medium devices (tablets, 768px and up)
@media (min-width: $tablet) and (max-width: $laptop - 1){
    .container{
        cursor: pointer;
        overflow: visible;
        position: relative;
        background-color: $card-bg;
        padding-bottom: 18px;
        padding-top: 48px;
        height: 410px;
        width: 280px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $font-nasalization;
    }

    .imagenCard {
        margin-bottom: 12px;
        height: 244px;
        width: 244px;
    }

    .texto {
        font-size: 20px;
        margin-top: 6px;
    }

    .sale{
        background-color: $secondary-blue;
        width: 53px;
        height: 53px;
        border-radius: 50px;
        font-family: $font-nasalization;
        font-size: 15px;
        color: white;
        position: absolute;
        top: -10px;
        right: -10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .soldOut{
        cursor: pointer;
        overflow: visible;
        position: relative;
        background-color: $card-bg;
        padding-bottom: 18px;
        padding-top: 48px;
        height: 410px;
        width: 280px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $font-nasalization;
        opacity: 0.6;
    }
    
    .textSoldOut{
        font-size: 40px;
        color: $secondary-blue;
        transform: rotate(-30deg);
        text-align: "center";
        position: absolute;
        top: 160px;
        right: 30px;
        font-weight: bold;
    }
};

// Large devices (desktops, 992px and up)
@media (min-width: $laptop) and (max-width: $desktop - 1){

    .container{
        cursor: pointer;
        overflow: visible;
        position: relative;
        background-color: $card-bg;
        padding-bottom: 18px;
        padding-top: 48px;
        height: 410px;
        width: 280px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $font-nasalization;
    }

    .imagenCard {
        margin-bottom: 12px;
        height: 244px;
        width: 244px;
    }

    .texto {
        font-size: 20px;
        margin-top: 6px;
    }

    .sale{
        background-color: $secondary-blue;
        width: 53px;
        height: 53px;
        border-radius: 50px;
        font-family: $font-nasalization;
        font-size: 15px;
        color: white;
        position: absolute;
        top: -10px;
        right: -10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .soldOut{
        cursor: pointer;
        overflow: visible;
        position: relative;
        background-color: $card-bg;
        padding-bottom: 18px;
        padding-top: 48px;
        height: 410px;
        width: 280px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $font-nasalization;
        opacity: 0.6;
    }
    
    .textSoldOut{
        font-size: 40px;
        color: $secondary-blue;
        transform: rotate(-30deg);
        text-align: "center";
        position: absolute;
        top: 160px;
        right: 30px;
        font-weight: bold;
    }
};

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $desktop){

    .container{
        cursor: pointer;
        overflow: visible;
        position: relative;
        background-color: $card-bg;
        padding-bottom: 18px;
        padding-top: 48px;
        height: 410px;
        width: 280px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $font-nasalization;
    }

    .imagenCard {
        margin-bottom: 12px;
        height: 244px;
        width: 244px;
    }

    .texto {
        font-size: 20px;
        margin-top: 6px;
    }

    .sale{
        background-color: $secondary-blue;
        width: 53px;
        height: 53px;
        border-radius: 50px;
        font-family: $font-nasalization;
        font-size: 15px;
        color: white;
        position: absolute;
        top: -10px;
        right: -10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .soldOut{
        cursor: pointer;
        overflow: visible;
        position: relative;
        background-color: $card-bg;
        padding-bottom: 18px;
        padding-top: 48px;
        height: 410px;
        width: 280px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $font-nasalization;
        opacity: 0.6;
    }
    
    .textSoldOut{
        font-size: 40px;
        color: $secondary-blue;
        transform: rotate(-30deg);
        text-align: "center";
        position: absolute;
        top: 160px;
        right: 30px;
        font-weight: bold;
    }
};
