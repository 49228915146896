@import "../../Styles/breakpoints.scss";
@import "../../Styles/fonts.scss";
@import "../../Styles/colors.scss";

@media (max-width: $mobile - 1) {
  .back {
    width: 1;
    margin-top: 20px;
    font-family: "Nasalization";
    color: $go-back;
    font-size: 10px;
    cursor: pointer;
    margin-left: 20px;
  }

  .container {
    padding-bottom: 50px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    .card {
      display: flex;
      flex-direction: column;
      color: white;

      .cardContainer {
        display: flex;
        flex-direction: column;
        width: 100vw;
        margin-top: 20px;

        .topContainer {
          display: flex;
          flex-direction: column;
          width: 100vw;

          .videoContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: black;
            overflow: hidden;
            position: relative;

            .loadMessageContainer {
              height: 100%;
              width: 100%;
              background-color: black;
              z-index: 2;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 0;

              .loadMessage {
                font-family: $font-nasalization;
                color: white;
              }
            }
          }

          .topRightContainer {
            background-color: #060f27;

            .title {
              color: $logout;
              font-weight: bold;
              padding-bottom: 10px;
              font-family: $font-nsBold;
              font-size: 14px;
            }

            .featuresContainer {
              display: flex;
              padding-right: 15px;
            }

            .features {
              font-family: $font-nsRegular;
              line-height: 1.3em;
            }

            .features::before {
              content: "- ";
            }

            .features:first-child::before {
              content: none;
            }

            .abilityText {
              padding-right: 50px;
              font-family: $font-nsRegular;
              line-height: 1.3em;
            }

            .cont1 {
              display: flex;
              padding-left: 25px;
              margin-top: 30px;

              .cont2 {
                display: flex;
                flex-direction: column;
                gap: 30px;

                .cont2a {
                  display: flex;
                  flex-direction: column;

                  .cont3 {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    overflow: visible;

                    .cont3a {
                      display: flex;
                      flex-direction: column;
                      gap: 7px;
                      font-size: 12px;
                    }

                    .cont3b {
                      font-size: 12px;

                      .storyText {
                        padding-right: 50px;
                        font-family: $font-nsRegular;
                        line-height: 1.3em;
                        padding-bottom: 1em;

                      }
                    }
                  }

                  .cont3Weapon {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    overflow: visible;
                    font-size: 12px;

                    .cont3a {
                      display: flex;
                      flex-direction: column;
                      gap: 7px;
                    }

                    .cont3b {
                      width: 100%;

                      .storyText {
                        padding-right: 50px;
                        font-family: $font-nsRegular;
                        line-height: 1.3em;
                        padding-bottom: 1em;

                      }
                    }
                  }

                  .skillsContainer {
                    margin-top: 30px;
                    display: flex;
                    flex-direction: column;
                    font-size: 12px;

                    .body {
                      font-family: $font-nsRegular;
                    }
                  }

                  .statsContainer {
                    margin-top: 30px;
                    display: flex;
                    flex-direction: column;
                    gap: 7px;

                    .itemStatContainer {
                      display: flex;
                      gap: 15px;
                      align-items: center;
                      justify-content: flex-start;

                      img {
                        width: 45px;
                      }

                      .itemStatInfo {
                        display: flex;
                        flex-direction: column;
                        font-size: 12px;
                        font-family: $font-nsRegular;
                      }
                    }
                  }
                }

                .cont2b {
                  padding-bottom: 25px;

                  .p2eContainer {
                    display: flex;
                    flex-direction: column;

                    .p2eContainerA {
                      display: flex;
                      flex-direction: column;
                      gap: 5px;
                      font-family: $font-nsRegular;
                      line-height: 1.3em;
                    }

                    .p2eContainerB {
                      margin-top: 5px;
                      display: flex;
                      flex-direction: column;
                      gap: 5px;
                      font-family: $font-nsRegular;
                      line-height: 1.3em;
                    }

                    .p2eItemContainer {
                      display: flex;
                      align-items: center;
                      gap: 10px;
                      font-size: 12px;

                      .p2eIcon {
                        width: 25px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .bottomContainer {
          background-color: black;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .bottomLeftContainer {
            width: 100vw;
            height: 60px;
            background-color: #242339;
            padding: 0 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: $font-nsRegular;
            font-size: 10px;

            .item {
              display: flex;
              align-items: center;
              gap: 10px;

              .itemName {
                color: $account-name;
                font-size: 18px;
                font-family: $font-nasalization;
                font-weight: bold;
              }

              .repName {
                font-size: 10px;
                font-family: $font-nsRegular;
                line-height: 1.3em;
              }

              .price {
                font-family: $font-nsRegular;
                line-height: 1.3em;
                color: yellow;
                padding-left: 30px;
              }

              .rarity {
                font-size: 10px;
                font-family: $font-nsRegular;
                line-height: 1.3em;
              }
            }
          }

          .button {
            width: 100%;

            button {
              width: 100%;
              height: 60px;
              border: none;
              cursor: pointer;
              font-size: 14px;
              background-color: $secondary-blue;
              color: white;
              font-family: $font-nasalization;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $mobile) and (max-width: $laptop - 1) {
  .back {
    width: 1;
    margin-top: 30px;
    font-family: "Nasalization";
    color: $go-back;
    font-size: 10px;
    cursor: pointer;
    margin-left: 20px;
  }

  .container {
    padding-bottom: 50px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    .card {
      display: flex;
      flex-direction: column;
      color: white;

      .cardContainer {
        display: flex;
        flex-direction: column;
        width: 80vw;
        margin-top: 30px;

        .topContainer {
          display: flex;
          flex-direction: column;
          width: 80vw;

          .videoContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: black;
            overflow: hidden;
            position: relative;

            .loadMessageContainer {
              height: 100%;
              width: 100%;
              background-color: black;
              z-index: 2;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 0;

              .loadMessage {
                font-family: $font-nasalization;
                color: white;
              }
            }
          }

          .topRightContainer {
            background-color: #060f27;

            .title {
              color: $logout;
              font-weight: bold;
              padding-bottom: 10px;
              font-family: $font-nsBold;
              font-size: 14px;
            }

            .featuresContainer {
              display: flex;
            }

            .features {
              font-family: $font-nsRegular;
              line-height: 1.3em;
            }

            .features::before {
              content: "- ";
            }

            .features:first-child::before {
              content: none;
            }

            .abilityText {
              padding-right: 50px;
              font-family: $font-nsRegular;
              line-height: 1.3em;
            }

            .cont1 {
              display: flex;
              padding-left: 30px;
              margin-top: 30px;

              .cont2 {
                display: flex;
                flex-direction: column;
                gap: 30px;

                .cont2a {
                  display: flex;

                  .cont3 {
                    display: flex;
                    flex-direction: column;
                    width: 55%;
                    gap: 30px;
                    overflow: visible;

                    .cont3a {
                      display: flex;
                      flex-direction: column;
                      gap: 7px;
                      font-size: 13px;
                    }

                    .cont3b {
                      font-size: 13px;
                      .storyText {
                        padding-right: 30px;
                        font-family: $font-nsRegular;
                        line-height: 1.3em;
                        padding-bottom: 1em;

                      }
                    }
                  }

                  .cont3Weapon {
                    display: flex;
                    flex-direction: column;
                    width: 75%;
                    gap: 30px;
                    overflow: visible;
                    font-size: 13px;

                    .cont3a {
                      display: flex;
                      flex-direction: column;
                      gap: 7px;
                    }

                    .cont3b {
                      width: 100%;

                      .storyText {
                        padding-right: 30px;
                        font-family: $font-nsRegular;
                        line-height: 1.3em;
                        padding-bottom: 1em;
                      }
                    }
                  }

                  .skillsContainer {
                    width: 20%;
                    display: flex;
                    flex-direction: column;
                    gap: 7px;
                    font-size: 13px;

                    .body {
                      font-family: $font-nsRegular;
                    }
                  }

                  .statsContainer {
                    // width: 20%;
                    display: flex;
                    flex-direction: column;
                    gap: 7px;
                    padding-right: 15px;

                    .itemStatContainer {
                      display: flex;
                      gap: 15px;
                      align-items: center;
                      justify-content: flex-start;

                      img {
                        width: 45px;
                      }

                      .itemStatInfo {
                        display: flex;
                        flex-direction: column;
                        font-size: 13px;
                        font-family: $font-nsRegular;
                      }
                    }
                  }
                }

                .cont2b {
                  padding-bottom: 25px;

                  .p2eContainer {
                    display: flex;

                    .p2eContainerA {
                      display: flex;
                      flex-direction: column;
                      gap: 5px;
                      width: 55%;
                      font-family: $font-nsRegular;
                      line-height: 1.3em;
                    }

                    .p2eContainerB {
                      display: flex;
                      flex-direction: column;
                      gap: 5px;
                      font-family: $font-nsRegular;
                      line-height: 1.3em;
                    }

                    .p2eItemContainer {
                      display: flex;
                      align-items: center;
                      gap: 10px;
                      font-size: 13px;

                      .p2eIcon {
                        width: 25px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .bottomContainer {
          height: 60px;
          background-color: black;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .bottomLeftContainer {
            height: 60px;
            background-color: #242339;
            padding: 0 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 75%;
            font-family: $font-nsRegular;
            font-size: 13px;

            .item {
              display: flex;
              align-items: center;
              gap: 10px;

              .itemName {
                color: $account-name;
                font-size: 18px;
                font-family: $font-nasalization;
                font-weight: bold;
              }

              .repName {
                font-size: 11px;
                font-family: $font-nsRegular;
                line-height: 1.3em;
              }

              .price {
                font-family: $font-nsRegular;
                line-height: 1.3em;
                color: yellow;
                padding-left: 30px;
              }

              .rarity {
                font-size: 11px;
                font-family: $font-nsRegular;
                line-height: 1.3em;
              }
            }
          }

          .button {
            width: 30%;

            button {
              width: 100%;
              height: 60px;
              border: none;
              cursor: pointer;
              font-size: 14px;
              background-color: $secondary-blue;
              color: white;
              font-family: $font-nasalization;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $laptop) and (max-width: $desktop - 1) {
  .back {
    padding-top: 20px;
    font-family: "Nasalization";
    color: $go-back;
    font-size: 10px;
    cursor: pointer;
    padding-left: 20px;
    position: absolute;
    top: 80px;
    z-index: 2;
  }

  .container {
    width: 100vw;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;

    .card {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: white;
      height: 100%;
      width: 100%;

      .cardContainer {
        display: flex;
        flex-direction: column;
        width: 90vw;
        margin-top: 30px;

        .topContainer {
          height: 440px;
          display: flex;
          width: 90vw;

          .videoContainer {
            width: 30%;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: black;
            overflow: hidden;
            position: relative;

            .pinVideo {
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%);
              height: 100%;
            }
            .loadMessageContainer {
              height: 100%;
              width: 100%;
              background-color: black;
              z-index: 2;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top:0;

              .loadMessage {
                font-family: $font-nasalization;
                color: white;
              }
            }
          }

          .topRightContainer {
            background-color: #060f27;
            width: 70%;

            .title {
              color: $logout;
              font-weight: bold;
              padding-bottom: 10px;
              font-family: $font-nsBold;
              font-size: 14px;
            }

            .featuresContainer {
              display: flex;
            }

            .features {
              font-family: $font-nsRegular;
              line-height: 1.3em;
            }

            .features::before {
              content: "- ";
            }

            .features:first-child::before {
              content: none;
            }

            .abilityText {
              padding-right: 50px;
              font-family: $font-nsRegular;
              line-height: 1.3em;
            }

            .cont1 {
              display: flex;
              padding-left: 30px;
              margin-top: 30px;

              .cont2 {
                display: flex;
                flex-direction: column;
                gap: 30px;

                .cont2a {
                  display: flex;

                  .cont3 {
                    display: flex;
                    flex-direction: column;
                    width: 55%;
                    gap: 30px;
                    overflow: visible;

                    .cont3a {
                      display: flex;
                      flex-direction: column;
                      gap: 7px;
                      font-size: 13px;
                    }

                    .cont3b {
                      font-size: 13px;
                      .storyText {
                        padding-right: 50px;
                        font-family: $font-nsRegular;
                        line-height: 1.3em;
                        padding-bottom: 1em;
                      }
                    }
                  }

                  .cont3Weapon {
                    display: flex;
                    flex-direction: column;
                    width: 75%;
                    gap: 30px;
                    overflow: visible;

                    .cont3a {
                      display: flex;
                      flex-direction: column;
                      gap: 7px;
                    }

                    .cont3b {
                      width: 100%;

                      .storyText {
                        padding-right: 50px;
                        font-family: $font-nsRegular;
                        line-height: 1.3em;
                        padding-bottom: 1em;
                      }
                    }
                  }

                  .skillsContainer {
                    width: 20%;
                    display: flex;
                    flex-direction: column;
                    gap: 7px;
                    font-size: 13px;

                    .body {
                      font-family: $font-nsRegular;
                    }
                  }

                  .statsContainer {
                    // width: 20%;
                    display: flex;
                    flex-direction: column;
                    gap: 7px;

                    .itemStatContainer {
                      display: flex;
                      // gap: 15px;
                      align-items: center;
                      justify-content: flex-start;

                      img {
                        width: 60px;
                      }

                      .itemStatInfo {
                        display: flex;
                        flex-direction: column;
                        font-size: 13px;
                        font-family: $font-nsRegular;
                      }
                    }
                  }
                }

                .cont2b {
                  .p2eContainer {
                    display: flex;

                    .p2eContainerA {
                      display: flex;
                      flex-direction: column;
                      gap: 5px;
                      width: 55%;
                      font-family: $font-nsRegular;
                      line-height: 1.3em;
                    }

                    .p2eContainerB {
                      display: flex;
                      flex-direction: column;
                      gap: 5px;
                      font-family: $font-nsRegular;
                      line-height: 1.3em;
                    }

                    .p2eItemContainer {
                      display: flex;
                      align-items: center;
                      gap: 10px;
                      font-size: 13px;

                      .p2eIcon {
                        width: 25px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .bottomContainer {
          height: 60px;
          background-color: black;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .bottomLeftContainer {
            height: 60px;
            background-color: #242339;
            padding: 0 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 75%;
            font-family: $font-nsRegular;
            font-size: 13px;

            .item {
              display: flex;
              align-items: center;
              gap: 10px;

              .itemName {
                color: $account-name;
                font-size: 22px;
                font-family: $font-nasalization;
                font-weight: bold;
              }

              .repName {
                font-size: 13px;
                font-family: $font-nsRegular;
                line-height: 1.3em;
              }

              .price {
                font-family: $font-nsRegular;
                line-height: 1.3em;
                color: yellow;
                padding-left: 30px;
              }

              .rarity {
                font-size: 13px;
                font-family: $font-nsRegular;
                line-height: 1.3em;
              }
            }
          }

          .button {
            width: 30%;

            button {
              width: 100%;
              height: 60px;
              border: none;
              cursor: pointer;
              font-size: 14px;
              background-color: $secondary-blue;
              color: white;
              font-family: $font-nasalization;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $desktop) and (max-width: $HD - 1) {
  .back {
    padding-top: 20px;
    font-family: "Nasalization";
    color: $go-back;
    font-size: 10px;
    cursor: pointer;
    padding-left: 20px;
    position: absolute;
    top: 80px;
    z-index: 2;
  }

  .container {
    width: 100vw;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;

    .card {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: white;
      height: 100%;
      width: 100%;

      .cardContainer {
        display: flex;
        flex-direction: column;
        width: 90vw;
        margin-top: 30px;

        .topContainer {
          height: 440px;
          display: flex;
          width: 90vw;

          .videoContainer {
            width: 30%;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: black;
            overflow: hidden;
            .pinVideo {
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%);
              height: 100%;
            }
            .loadMessageContainer {
              height: 100%;
              width: 100%;
              background-color: black;
              z-index: 2;
              display: flex;
              justify-content: center;
              align-items: center;

              .loadMessage {
                font-family: $font-nasalization;
                color: white;
              }
            }
          }

          .topRightContainer {
            background-color: #060f27;
            width: 70%;

            .title {
              color: $logout;
              font-weight: bold;
              padding-bottom: 10px;
              font-family: $font-nsBold;
              font-size: 16px;
            }

            .featuresContainer {
              display: flex;
            }

            .features {
              font-family: $font-nsRegular;
              line-height: 1.3em;
            }

            .features::before {
              content: "- ";
            }

            .features:first-child::before {
              content: none;
            }

            .abilityText {
              padding-right: 50px;
              font-family: $font-nsRegular;
              line-height: 1.3em;
            }

            .cont1 {
              display: flex;
              padding-left: 30px;
              margin-top: 30px;

              .cont2 {
                display: flex;
                flex-direction: column;
                gap: 30px;

                .cont2a {
                  display: flex;

                  .cont3 {
                    display: flex;
                    flex-direction: column;
                    width: 55%;
                    gap: 30px;
                    overflow: visible;

                    .cont3a {
                      display: flex;
                      flex-direction: column;
                      gap: 7px;
                      font-size: 14px;
                    }

                    .cont3b {
                      font-size: 14px;
                      .storyText {
                        padding-right: 50px;
                        font-family: $font-nsRegular;
                        line-height: 1.3em;
                        padding-bottom: 1em;
                      }
                    }
                  }

                  .cont3Weapon {
                    display: flex;
                    flex-direction: column;
                    width: 75%;
                    gap: 30px;
                    overflow: visible;

                    .cont3a {
                      display: flex;
                      flex-direction: column;
                      gap: 7px;
                    }

                    .cont3b {
                      width: 100%;

                      .storyText {
                        padding-right: 50px;
                        font-family: $font-nsRegular;
                        line-height: 1.3em;
                        padding-bottom: 1em;
                      }
                    }
                  }

                  .skillsContainer {
                    width: 20%;
                    display: flex;
                    flex-direction: column;
                    gap: 7px;
                    font-size: 14px;

                    .body {
                      font-family: $font-nsRegular;
                    }
                  }

                  .statsContainer {
                    // width: 20%;
                    display: flex;
                    flex-direction: column;
                    gap: 7px;
                    margin-right: 30px;


                    .itemStatContainer {
                      display: flex;
                      // gap: 15px;
                      align-items: center;
                      justify-content: flex-start;

                      img {
                        width: 60px;
                      }

                      .itemStatInfo {
                        display: flex;
                        flex-direction: column;
                        font-size: 14px;
                        font-family: $font-nsRegular;
                      }
                    }
                  }
                }

                .cont2b {
                  .p2eContainer {
                    display: flex;

                    .p2eContainerA {
                      display: flex;
                      flex-direction: column;
                      gap: 5px;
                      width: 55%;
                      font-family: $font-nsRegular;
                      line-height: 1.3em;
                    }

                    .p2eContainerB {
                      display: flex;
                      flex-direction: column;
                      gap: 5px;
                      font-family: $font-nsRegular;
                      line-height: 1.3em;
                    }

                    .p2eItemContainer {
                      display: flex;
                      align-items: center;
                      gap: 10px;
                      font-size: 14px;

                      .p2eIcon {
                        width: 25px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .bottomContainer {
          height: 60px;
          background-color: black;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .bottomLeftContainer {
            height: 60px;
            background-color: #242339;
            padding: 0 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 75%;
            font-family: $font-nsRegular;
            font-size: 14px;

            .item {
              display: flex;
              align-items: center;
              gap: 10px;

              .itemName {
                color: $account-name;
                font-size: 24px;
                font-family: $font-nasalization;
                font-weight: bold;
              }

              .repName {
                font-size: 14px;
                font-family: $font-nsRegular;
                line-height: 1.3em;
              }

              .price {
                font-family: $font-nsRegular;
                line-height: 1.3em;
                color: yellow;
                padding-left: 30px;
              }

              .rarity {
                font-size: 14px;
                font-family: $font-nsRegular;
                line-height: 1.3em;
              }
            }
          }

          .button {
            width: 30%;

            button {
              width: 100%;
              height: 60px;
              border: none;
              cursor: pointer;
              font-size: 16px;
              background-color: $secondary-blue;
              color: white;
              font-family: $font-nasalization;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $HD) {
  .back {
    padding-top: 50px;
    font-family: "Nasalization";
    color: $go-back;
    font-size: 15px;
    cursor: pointer;
    padding-left: 20px;
    position: absolute;
    top: 80px;
    z-index: 2;
  }

  .container {
    width: 100vw;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;

    .card {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: white;
      height: 100%;
      width: 100%;

      .imgContainer {
        width: 50%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
        overflow: hidden;

        .pinVideo {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%);
          height: 90%;
          top: 5%;
        }
        .loadMessageContainer {
          height: 100%;
          width: 100%;
          background-color: black;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;

          .loadMessage {
            font-family: $font-nasalization;
            color: white;
          }
        }
      }
    }

    .cardContainer {
      display: flex;
      flex-direction: column;
      width: 90vw;
      margin-top: 30px;

      .topContainer {
        height: 600px;
        display: flex;
        width: 90vw;

        .videoContainer {
          width: 30%;
          height: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: black;
          overflow: hidden;
          .pinVideo {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            height: 100%;
          }
          .loadMessageContainer {
            height: 100%;
            width: 100%;
            background-color: black;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;

            .loadMessage {
              font-family: $font-nasalization;
              color: white;
            }
          }
        }

        .topRightContainer {
          background-color: #060f27;
          width: 70%;

          .title {
            color: $logout;
            font-weight: bold;
            padding-bottom: 10px;
            font-family: $font-nsBold;
            font-size: 25px;
          }

          .featuresContainer {
            display: flex;
          }

          .features{
            font-family: $font-nsRegular;
            line-height: 1.3em;
            font-size: 20px;
          }

          .features::before {
            content: "- ";
          }

          .features:first-child::before {
            content: none;
          }

          .abilityText {
            padding-right: 50px;
            font-family: $font-nsRegular;
            line-height: 1.3em;
          }

          .cont1 {
            display: flex;
            padding-left: 50px;
            margin-top: 40px;
            font-size: 20px;
            font-family: $font-nsRegular;
            line-height: 1.3em;

            .cont2 {
              display: flex;
              flex-direction: column;
              gap: 50px;

              .cont2a {
                display: flex;
                // justify-content: space-between;

                .cont3 {
                  display: flex;
                  flex-direction: column;
                  width: 55%;
                  gap: 50px;
                  overflow: visible;

                  .cont3a {
                    display: flex;
                    flex-direction: column;
                    gap: 7px;
                  }

                  .cont3b {
                    width: 120%;

                    .storyText {
                      padding-right: 50px;
                      font-family: $font-nsRegular;
                      line-height: 1.3em;
                      padding-bottom: 1em;
                    }
                  }
                }

                .cont3Weapon {
                  display: flex;
                  flex-direction: column;
                  width: 70%;
                  gap: 30px;
                  overflow: visible;

                  .cont3a {
                    display: flex;
                    flex-direction: column;
                    gap: 7px;
                  }

                  .cont3b {
                    width: 100%;
                    .storyText {
                      padding-right: 50px;
                      font-family: $font-nsRegular;
                      line-height: 1.3em;
                      padding-bottom: 1em;
                    }
                  }
                }

                .skillsContainer {
                  width: 20%;
                  display: flex;
                  flex-direction: column;
                  gap: 7px;
                }

                .statsContainer {
                  width: 20%;
                  display: flex;
                  flex-direction: column;
                  gap: 7px;
                  margin-right: 30px;

                  .itemStatContainer {
                    display: flex;
                    // gap: 15px;
                    align-items: center;
                    justify-content: flex-start;

                    img {
                      width: 80px;
                    }

                    .itemStatInfo {
                      display: flex;
                      flex-direction: column;
                      font-size: 20px;
                      width: 100%;


                      .itemStatTitle {
                        font-weight: normal;
                      }
                    }
                  }
                }
              }

              .cont2b {
                .p2eContainer {
                  display: flex;

                  .p2eContainerA {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: 55%;
                    font-family: $font-nsRegular;
                    line-height: 1.3em;
                  }

                  .p2eContainerB {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-family: $font-nsRegular;
                    line-height: 1.3em;
                  }

                  .p2eItemContainer {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 20px;

                    .p2eIcon {
                      width: 35px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .bottomContainer {
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bottomLeftContainer {
          height: 100%;
          background-color: #242339;
          padding: 0 45px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 75%;
          font-size: 25px;
          font-family: $font-nsRegular;

          .item {
            display: flex;
            align-items: center;
            gap: 15px;

            .itemName {
              color: $account-name;
              font-size: 35px;
              font-family: $font-nasalization;
              font-weight: bold;
            }

            .repName {
              font-size: 20px;
              font-family: $font-nsRegular;
              line-height: 1.3em;
            }

            .price {
              font-family: $font-nsRegular;
              line-height: 1.3em;
              color: yellow;
              padding-left: 60px;
            }

            .rarity {
              font-size: 20px;
              font-family: $font-nsRegular;
              line-height: 1.3em;
            }

          }
        }

        .button {
          width: 30%;
          height: 100%;

          button {
            width: 100%;
            height: 100%;
            border: none;
            cursor: pointer;
            font-size: 26px;
            background-color: $secondary-blue;
            color: white;
            font-family: $font-nasalization;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.35);
}
