@import "../../../../../Styles/colors.scss";
@import "../../../../../Styles/fonts.scss";
@import "../../../../../Styles/animations.scss";

$mobile: 576px;
$tablet: 767px;
$laptop: 992px;
$desktop: 1200px;
$HD: 1400px;

// Medium devices (tablets, 768px and up)
@media (min-width: $tablet) and (max-width: $laptop - 1){
    .general {
    max-height: 500px;
    padding-top: 30px;
    padding-bottom: 15px;
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    width: 700px;
    background-color: $blue-dialog-background;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
    border-radius: 15px;
    animation: modalLeftRight 0.2s, modalFade 0.25s;

    p {
      position: absolute;
      top: 10px;
      font-family: $font-nasalization;
      font-weight: 700;
      font-size: 26px;
      color: white;
      text-align: center;
    }
  }

  .cross {
    transform: scale(0.7);
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $laptop) and (max-width: $HD - 1){
  .general {
    max-height: 500px;
    padding-top: 45px;
    padding-bottom: 15px;
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    width: 1000px;
    background-color: $blue-dialog-background;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
    border-radius: 15px;
    animation: modalLeftRight 0.2s, modalFade 0.25s;
  
    p {
      padding-top: 15px;
      position: absolute;
      top: 15px;
      font-family: $font-nasalization;
      font-weight: 700;
      font-size: 26px;
      color: white;
      text-align: center;
    }
  }
  
  .cross {
    transform: scale(0.7);
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $HD){
  .general {
    width: 1500px;
    max-height: 650px;
    padding-top: 30px;
    padding-bottom: 15px;
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    background-color: $blue-dialog-background;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
    border-radius: 15px;
    animation: modalLeftRight 0.2s, modalFade 0.25s;
  
    p {
      position: absolute;
      top: 15px;
      font-family: $font-nasalization;
      font-weight: 700;
      font-size: 26px;
      color: white;
      text-align: center;
    }
  }
  
  .cross {
    transform: scale(0.7);
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
  }
}
