@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/breakpoints.scss";
@import "../../../../Styles/animations.scss";

@media (max-width: $desktop - 1) {
  .content3 {
    -webkit-mask-image: url('../../../../Assets/img/headPrivacy.png'), linear-gradient(black, black);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: top, bottom;
    -webkit-mask-size: 100% 5%, 100% 95%;
    mask-image: url('../../../../Assets/img/headPrivacy.png'), linear-gradient(black, black);
    mask-position: top bottom;
    mask-size: 100% 5%, 100% 95%;
    mask-repeat: no-repeat;
    position: relative;
    top: -50px;
    margin-bottom: -50px;
    padding: 70px 0 60px 0;
    overflow: hidden;
  }

  .bgSection{
    z-index: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 110%;
    object-fit: cover;
    animation: easyZoom 15s ease-in-out 0s infinite alternate;
  }

  .container {
    width: calc(100% - 20px);
    margin: 10px;
    color: #fff;
    text-align: center;
    font-size: 1em;
    z-index: 1;
    position: relative;

    h3 {
      max-width: 680px;
      margin: 0 auto;
      color: white;
      background: linear-gradient(to bottom, #f9dbab, #fb90cf, #4837e9);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      font-size: 1.3em;
      padding: 35px;
      text-transform: uppercase;
      font-family: $font-monkyta;
    }

    p {
      display: flex;
      text-align: center;
      position: relative;
      font-size: 1em;
      font-family: $font-route159;
    }

    img {
      padding: 30px;
      max-width: 150px;
    }
  }
}

@media (min-width: $desktop) {
  .content3 {
    // -webkit-mask-image: url('../../../../Assets/img/headPrivacy.png'), linear-gradient(black, black);
    // -webkit-mask-repeat: no-repeat;
    // -webkit-mask-position: bottom;
    // -webkit-mask-size: 100% 12%, 100% 88%;
    // mask-image: url('../../../../Assets/img/headPrivacy.png'), linear-gradient(black, black);
    // mask-position: bottom;
    // mask-size: 100% 12%, 100% 88%;
    // mask-repeat: no-repeat;
    position: relative;
    // top: -50px;
    padding: 70px 0 60px 0;
    overflow-y: hidden;
    // margin-bottom: -50px;
  }

  .bgSection{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 125%;
    object-fit: cover;
    animation: easyZoom 10s ease-in-out 0s infinite alternate;
  }

  .container {
    width: calc(100% - 20px);
    margin: 10px;
    color: #fff;
    text-align: center;
    font-size: 1em;
    position: relative;
    z-index: 1;

    h3 {
      max-width: 680px;
      margin: 0 auto;
      background: linear-gradient(to bottom, #f9dbab, #fb90cf, #4837e9);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      font-size: 2.4em;
      padding: 35px;
      text-transform: uppercase;
      font-family: $font-monkyta;
    }

    p {
      display: flex;
      text-align: center;
      margin: 0 130px;
      position: relative;
      font-size: 1em;
      font-family: $font-route159;
    }

    img {
      padding: 30px;
      max-width: 150px;
    }
  }
}
