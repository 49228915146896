@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/breakpoints.scss";

$hd: 1400px;

@media (max-width: $mobile - 1) {
  .content2 {
    background: url("../../../../Assets/img/BannerCoinsMobile.jpg") bottom
      center no-repeat #a427e3;
    -webkit-mask-image: url("../../../../Assets/img/headPrivacy.png"),
      linear-gradient(black, black);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: top, bottom;
    -webkit-mask-size: 110% 5%, 110% 95%;
    mask-image: url("../../../../Assets/img/headPrivacy.png"),
      linear-gradient(black, black);
    mask-position: top bottom;
    mask-size: 110% 5%, 110% 95%;
    mask-repeat: no-repeat;
    width: 110%;
    height: 520px;
    color: #fff;
    text-align: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    top: -30px;
    margin-bottom: -30px;
    left: -17px;

    article {
      margin-top: 50px;
      padding: 0px 20px 0 0px;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        font-size: 50px;
        font-family: $font-dimboRegular;
        letter-spacing: 3px;
        font-weight: 500;
        text-shadow: 2px 2px 5px rgb(0, 0, 0, 0.3);

        b {
          font-family: $font-dimboItalic;
        }
      }

      h4 {
        padding: 10px 0px;
        font-size: 20px;
        font-family: $font-dimboRegular;
        font-weight: 500;
        letter-spacing: 1px;

        b {
          color: #35dcfe;
        }
      }

      br {
        display: none;
      }

      p {
        padding-top: 10px;
        font-size: 11px;
        font-family: $font-nsBold;
      }
    }
  }
}

@media (min-width: $mobile) and (max-width: $tablet - 1) {
  .content2 {
    background: url("../../../../Assets/img/BannerCoinsMobile.jpg") bottom
      center no-repeat #a427e3;
    -webkit-mask-image: url("../../../../Assets/img/headPrivacy.png"),
      linear-gradient(black, black);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: top, bottom;
    -webkit-mask-size: 110% 5%, 110% 95%;
    mask-image: url("../../../../Assets/img/headPrivacy.png"),
      linear-gradient(black, black);
    mask-position: top bottom;
    mask-size: 110% 5%, 110% 95%;
    mask-repeat: no-repeat;
    width: 110%;
    height: 600px;
    padding: 0 0 130px 0;
    color: #fff;
    text-align: center;
    padding: 0 0 110px 0;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    top: -30px;
    margin-bottom: -30px;

    article {
      margin-top: 50px;
      padding: 0px 20px;
      text-align: center;
      font-family: $font-nsItalicBold;

      h2 {
        font-size: 60px;
        font-family: $font-dimboRegular;
        letter-spacing: 3px;
        font-weight: 500;
        text-shadow: 2px 2px 5px rgb(0, 0, 0, 0.3);

        b {
          font-family: $font-dimboItalic;
        }
      }

      h4 {
        padding: 10px 0px;
        font-size: 25px;
        font-family: $font-dimboRegular;
        font-weight: 500;
        letter-spacing: 1px;

        b {
          color: #35dcfe;
        }
      }

      br {
        display: none;
      }

      p {
        padding-top: 10px;
        font-size: 14px;
        font-family: $font-nsBold;
      }
    }
  }
}

@media (min-width: $tablet) and (max-width: $desktop - 1) {
  .content2 {
    background: url("../../../../Assets/img/BannerCoinsMobile.jpg") bottom
      center no-repeat #a427e3;
    -webkit-mask-image: url("../../../../Assets/img/headPrivacy.png"),
      linear-gradient(black, black);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: top, bottom;
    -webkit-mask-size: 110% 5%, 110% 95%;
    mask-image: url("../../../../Assets/img/headPrivacy.png"),
      linear-gradient(black, black);
    mask-position: top bottom;
    mask-size: 110% 5%, 110% 95%;
    mask-repeat: no-repeat;
    width: 110%;
    height: 700px;
    padding: 0 0 130px 0;
    color: #fff;
    text-align: center;
    padding: 0 0 110px 0;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    top: -30px;
    margin-bottom: -30px;

    article {
      margin-top: 50px;
      margin-left: 50px;
      text-align: center;
      font-family: $font-nsItalicBold;
      position: relative;

      h2 {
        font-size: 60px;
        font-family: $font-dimboRegular;
        letter-spacing: 3px;
        font-weight: 500;
        text-shadow: 2px 2px 5px rgb(0, 0, 0, 0.3);

        b {
          font-family: $font-dimboItalic;
        }
      }

      h4 {
        padding: 10px 0px;
        font-size: 25px;
        font-family: $font-dimboRegular;
        font-weight: 500;
        letter-spacing: 1px;

        b {
          color: #35dcfe;
        }
      }

      p {
        padding-top: 10px;
        font-size: 14px;
        font-family: $font-nsBold;
      }
    }
  }
}

@media (min-width: $desktop) and (max-width: $hd - 1) {
  .content2 {
    position: relative;
    background: url("../../../../Assets/img//BannerNcoins.png");
    z-index: 3;
    width: 105%;
    height: 360px;
    color: #fff;
    text-align: center;
    background-size: cover;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;

    article {
      margin-left: 60px;
      text-align: left;
      position: relative;

      button {
        margin: 10px 0;
        margin-left: 60px;
      }

      h2 {
        font-size: 60px;
        font-family: $font-dimboRegular;
        letter-spacing: 3px;
        font-weight: 500;
        text-shadow: 2px 2px 5px rgb(0, 0, 0, 0.3);

        b {
          font-family: $font-dimboItalic;
        }
      }

      h4 {
        padding: 10px 0px;
        font-size: 27px;
        font-family: $font-dimboRegular;
        font-weight: 500;
        letter-spacing: 1px;

        b {
          color: #35dcfe;
        }
      }

      p {
        font-size: 12px;
        font-family: $font-nsBold;
      }
    }
  }
}

@media (min-width: $hd) {
  .content2 {
    position: relative;
    background: url("../../../../Assets/img//BannerNcoins.png");
    width: 110%;
    height: 496px;
    color: #fff;
    text-align: center;
    background-size: cover;
    display: flex;
    justify-content: left;
    align-items: center;

    article {
      margin-left: 100px;
      text-align: left;
      position: relative;
      
      button {
        margin: 25px 0;
        margin-left: 75px;
      }

      h2 {
        font-size: 80px;
        font-family: $font-dimboRegular;
        letter-spacing: 3px;
        font-weight: 500;
        text-shadow: 2px 2px 5px rgb(0, 0, 0, 0.3);

        b {
          font-family: $font-dimboItalic;
        }
      }

      h4 {
        padding: 10px 0px;
        font-size: 37px;
        font-family: $font-dimboRegular;
        font-weight: 500;
        letter-spacing: 1px;

        b {
          color: #35dcfe;
        }
      }

      p {
        padding-top: 10px;
        font-size: 16px;
        font-family: $font-nsBold;
      }
    }
  }
}
