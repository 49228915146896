@import "../../Styles/breakpoints.scss";
@import '../../Styles/colors.scss';

// Extra mall devices (< 576px)
@media (max-width: $tablet - 1){
    .mainBar{
        width: 100px;
        height: 20px;
        margin: 5px 0 10px 0px;
        background-color: $deep-blue;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .internalTopBar{
        height: 7px;
        border-top-left-radius: 20px;
        margin: 0px 3px 0px 3px;
    }
    .internalBottomBar{
        height: 7px;
        border-bottom-left-radius: 20px;
        margin: 0px 3px 0px 3px;
    }
};

// Medium devices (tablets, 768px - 1200px)
@media (min-width: $tablet) and (max-width: $desktop - 1){
    .mainBar{
        width: 90px;
        height: 20px;
        margin: 5px 0 10px 0px;
        background-color: $deep-blue;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .internalTopBar{
        height: 7px;
        border-top-left-radius: 20px;
        margin: 0px 3px 0px 3px;
    }
    .internalBottomBar{
        height: 7px;
        border-bottom-left-radius: 20px;
        margin: 0px 3px 0px 3px;
    }
}

// 1200px - 1900 px
@media (min-width: $desktop) and (max-width: $HD - 1) {
    .mainBar{
        width: 100px;
        height: 20px;
        margin: 5px 0 10px 0px;
        background-color: $deep-blue;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .internalTopBar{
        height: 7px;
        border-top-left-radius: 20px;
        margin: 0px 3px 0px 3px;
    }
    .internalBottomBar{
        height: 7px;
        border-bottom-left-radius: 20px;
        margin: 0px 3px 0px 3px;
    }
}

@media (min-width: $HD) {
    .mainBar{
        width: 100%;
        height: 20px;
        margin: 5px 0 10px 0px;
        background-color: $deep-blue;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .internalTopBar{
        height: 7px;
        border-top-left-radius: 20px;
        margin: 0px 3px 0px 3px;
    }
    .internalBottomBar{
        height: 7px;
        border-bottom-left-radius: 20px;
        margin: 0px 3px 0px 3px;
    }
}
