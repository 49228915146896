@import "../../Styles/colors.scss";
@import "../../Styles/fonts.scss";
$mobile: 576px;
$tablet: 767px;
$laptop: 992px;
$desktop: 1200px;
$hd: 1400px;

// Extra mall devices (< 576px)
@media (max-width: $mobile - 1) {
  .popUpContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 0;
    justify-content: center;
    padding-top: 130px;
  }
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }

  .inputContainer {
    width: 100%;
    padding: 0 20px;
  }
  .errorMessage {
    font-size: 15px;
    color: #730909;
    font-family: $font-nasalization;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: $mobile) and (max-width: $tablet - 1) {
  .popUpContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 0;
    justify-content: center;
    padding-top: 130px;
  }
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .inputContainer {
    width: 100%;
    padding: 0 30px;
  }
  .errorMessage {
    font-size: 20px;
    color: #730909;
    font-family: $font-nasalization;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $tablet) and (max-width: $laptop - 1) {
  .popUpContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 0;
    justify-content: center;
    padding-top: 130px;
  }
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .inputContainer {
    width: 100%;
    padding: 0 40px;
  }
  .errorMessage {
    font-size: 20px;
    color: #730909;
    font-family: $font-nasalization;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $laptop) and (max-width: $desktop - 1) {
  .popUpContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 0;
    justify-content: center;
    padding-top: 130px;
  }
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 105vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .inputContainer {
    width: 100%;
    padding: 0 40px;
  }
  .errorMessage {
    font-size: 20px;
    color: #730909;
    font-family: $font-nasalization;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $desktop) and (max-width: $hd - 1) {
  .popUpContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 0;
    justify-content: center;
    padding-top: 130px;
  }

  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 105vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .inputContainer {
    width: 100%;
    padding: 0 50px;
  }
  .errorMessage {
    font-size: 20px;
    color: #730909;
    font-family: $font-nasalization;
  }
}

@media (min-width: $hd) {
  .popUpContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 0;
    justify-content: center;
    padding-top: 130px;
  }

  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1, 3, 32, 1);
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container:before {
    content: "";
    background: url("../../Assets/img/Bg-Home.png") bottom center no-repeat #000;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    z-index: 0;
    height: 100vh;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .inputContainer {
    width: 100%;
    padding: 0 50px;
  }
  .errorMessage {
    font-size: 20px;
    color: #730909;
    font-family: $font-nasalization;
  }
}
