@import "../../../../Styles/breakpoints.scss";

@media (max-width: $desktop - 1) {
    .container {
        width: 100vw;
        position: absolute;
        margin-top: 80px;
        min-height: 300px;
        background: linear-gradient(180deg, #041237 0%, rgba(4, 18, 55, 0) 100%);
        border-top: 1.5px solid;
        border-image: linear-gradient(180deg, #0146d5 0%, rgba(1, 70, 213, 0) 100%) 1;
        border-bottom: none;
        left: 0;
    }
}

@media (min-width: $desktop) {
    .container {
        width: 84vw;
        min-height: 300px;
        background: linear-gradient(180deg, #041237 0%, rgba(4, 18, 55, 0) 100%);
        border: 1.5px solid;
        border-image: linear-gradient(180deg, #0146d5 0%, rgba(1, 70, 213, 0) 100%) 1;
        border-bottom: none;
    }
}
