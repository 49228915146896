@import "../../../../Styles/colors.scss";
@import "../../../../Styles/breakpoints.scss";
@import "../../../../Styles/fonts.scss";

@media (max-width: $tablet - 1) {
  .borderCommon {
    width: 95vw;
    height: 149vw;
    max-width: 344px;
    max-height: 557px;
    background: linear-gradient(
      819deg,
      #6c6c6c 6.25%,
      #c5c5c5 52.19%,
      #6c6c6c 92.72%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .borderRare {
    width: 95vw;
    height: 149vw;
    max-width: 344px;
    max-height: 557px;
    background: linear-gradient(
      81.09deg,
      #a78043 6.25%,
      #fef7eb 52.19%,
      #8a6a2e 92.72%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .borderEpic {
    width: 95vw;
    height: 149vw;
    max-width: 344px;
    max-height: 557px;
    background: linear-gradient(
      156.45deg,
      #ecafef 0%,
      #ff0079 25.52%,
      #b53067 67.71%,
      #8343a8 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .borderLegendary {
    width: 95vw;
    height: 149vw;
    max-width: 344px;
    max-height: 557px;
    background: linear-gradient(
      153.06deg,
      #1bfcfd -0.31%,
      #0ca5d2 49.17%,
      #033060 80.94%,
      #14c5d5 99.69%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .cardNft {
    overflow: visible;
    position: relative;
    height: 95%;
    width: 94%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #030b23;
    border-radius: 3px;
  }

  .imgNft {
    width: 100%;
    height: 60%;
    object-fit: cover;
    background-color: black;
    border-radius: 6px;
  }

  .texts {
    height: 40%;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 7px;

    .itemName{
      font-size: 22px;
      padding: 0px 15px;
      text-align: center;
      color: white;
      font-family: "Nasalization";
    }

    p {
      font-size: 14px;
      padding: 0px 15px;
      text-align: center;
      color: white;
      font-family: "Nasalization";
    }

    .price {
      color: $logout;
    }

    .text2 {
      color: $account-name;
    }
  }

}

@media (min-width: $tablet) and (max-width: $desktop - 1) {
  .borderCommon {
    width: 45vw;
    height: 90vw;
    max-width: 344px;
    max-height: 557px;
    background: linear-gradient(
      819deg,
      #6c6c6c 6.25%,
      #c5c5c5 52.19%,
      #6c6c6c 92.72%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .borderRare {
    width: 45vw;
    max-width: 344px;
    height: 90vw;
    max-height: 557px;
    background: linear-gradient(
      81.09deg,
      #a78043 6.25%,
      #fef7eb 52.19%,
      #8a6a2e 92.72%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .borderEpic {
    width: 45vw;
    max-width: 344px;
    height: 90vw;
    max-height: 557px;
    background: linear-gradient(
      156.45deg,
      #ecafef 0%,
      #ff0079 25.52%,
      #b53067 67.71%,
      #8343a8 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .borderLegendary {
    width: 45vw;
    max-width: 344px;
    height: 90vw;
    max-height: 557px;
    background: linear-gradient(
      153.06deg,
      #1bfcfd -0.31%,
      #0ca5d2 49.17%,
      #033060 80.94%,
      #14c5d5 99.69%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .cardNft {
    overflow: visible;
    position: relative;
    height: 95%;
    width: 94%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #030b23;
    border-radius: 3px;
  }

  .imgNft {
    width: 100%;
    height: 60%;
    object-fit: cover;
    background-color: black;
    border-radius: 6px;
  }

  .texts {
    height: 40%;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 7px;

    .itemName{
      font-size: 26px;
      padding: 0px 15px;
      text-align: center;
      color: white;
      font-family: "Nasalization";
    }

    p {
      font-size: 16px;
      padding: 0px 15px;
      text-align: center;
      color: white;
      font-family: "Nasalization";
    }

    .price {
      color: $logout;
    }

    .text2 {
      color: $account-name;
    }
  }
  
}

@media (min-width: $desktop) {
  .borderCommon {
    width: 220px;
    height: 335px;
    background: linear-gradient(
      819deg,
      #6c6c6c 6.25%,
      #c5c5c5 52.19%,
      #6c6c6c 92.72%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .borderRare {
    width: 220px;
    height: 335px;
    background: linear-gradient(
      81.09deg,
      #a78043 6.25%,
      #fef7eb 52.19%,
      #8a6a2e 92.72%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .borderEpic {
    width: 220px;
    height: 335px;
    background: linear-gradient(
      156.45deg,
      #ecafef 0%,
      #ff0079 25.52%,
      #b53067 67.71%,
      #8343a8 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .borderLegendary {
    width: 220px;
    height: 335px;
    background: linear-gradient(
      153.06deg,
      #1bfcfd -0.31%,
      #0ca5d2 49.17%,
      #033060 80.94%,
      #14c5d5 99.69%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .cardNft {
    cursor: pointer;
    overflow: visible;
    position: relative;
    height: 95%;
    width: 94%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #030b23;
    border-radius: 3px;
  }

  .imgNft {
    width: 100%;
    object-fit: contain;
    background-color: black;
    bottom: 0;
  }

  .texts {
    height: calc(100% - 211px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .itemName{
      font-size: 18px;
      padding: 0px 15px;
      text-align: center;
      color: white;
      font-family: "Nasalization";
    }

    p {
      font-size: 12px;
      padding: 0px 15px;
      text-align: center;
      color: white;
      font-family: "Nasalization";
    }

    .price {
      color: $logout;
    }

    .text2 {
      color: $account-name;
    }
  }

}
