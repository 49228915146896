@import "../../../../Styles/fonts.scss";
@import "../../../../Styles/colors.scss";
@import "../../../../Styles/breakpoints.scss";

@media (max-width: $laptop - 1) {
  .card {
    display: flex;
    flex-direction: column;
    color: white;
    width: 100%;

    .imgContainer {
      width: 100%;
      height: 320px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(180deg, #0146d5 0%, rgba(1, 70, 213, 0) 100%);
    }

    .text {
      width: 100%;
      height: 100%;
      background-color: rgba(3, 11, 35, 0.8);

      .rare {
        padding-left: 30px;
        padding-top: 25px;
        font-family: $font-nasalization;
        font-size: 20px;
        font-weight: 700;
      }

      p {
        padding-left: 30px;
        padding-top: 15px;
        font-family: $font-nasalization;
        font-size: 12px;
        font-weight: 400;
        padding-right: 20px;
      }

      ul {
        list-style: none;
        padding-left: 30px;
        padding-top: 7px;
        font-family: $font-nasalization;
        font-size: 12px;
        font-weight: 400;
      }

      .price {
        padding-left: 30px;
        padding-top: 25px;
        font-family: $font-nasalization;
        font-size: 20px;
        font-weight: 700;
        color: $account-name;
      }

      button {
        margin-left: 30px;
        margin-top: 10px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 60px;
        margin-bottom: 20px;
      }
    }
  }
}

@media (min-width: $laptop) {
  .card {
    display: flex;
    flex-direction: row;
    color: white;

    .imgContainer {
      width: 250px;
      height: 320px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(180deg, #0146d5 0%, rgba(1, 70, 213, 0) 100%);
    }

    .text {
      width: 550px;
      height: 320px;
      background-color: rgba(3, 11, 35, 0.8);

      .rare {
        padding-left: 40px;
        padding-top: 45px;
        font-family: $font-nasalization;
        font-size: 25px;
        font-weight: 700;
      }

      p {
        padding-left: 40px;
        padding-top: 15px;
        font-family: $font-nasalization;
        font-size: 14px;
        font-weight: 400;
      }

      ul {
        list-style: none;
        padding-left: 40px;
        padding-top: 7px;
        font-family: $font-nasalization;
        font-size: 14px;
        font-weight: 400;
      }

      .price {
        padding-left: 40px;
        padding-top: 25px;
        font-family: $font-nasalization;
        font-size: 25px;
        font-weight: 700;
        color: $account-name;
      }

      button {
        margin-left: 40px;
        margin-top: 30px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 60px;
      }
    }
  }
}
