@import "../../Styles/colors.scss";
@import "../../Styles/fonts.scss";
@import "../../Styles/breakpoints.scss";

@media (max-width: $mobile - 1) {
  .container {
    width: 200px;
    border-radius: 3px;
    background-color: $blue-dialog-background;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;

    .text {
      font-family: $font-route159;
      font-weight: bold;
      color: white;
    }
  }
}

@media (min-width: $mobile) and (max-width: $tablet - 1) {
  .container {
    width: 200px;
    border-radius: 3px;
    background-color: $blue-dialog-background;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;

    .text {
      font-family: $font-route159;
      font-weight: bold;
      color: white;
    }
  }
}

@media (min-width: $tablet) and (max-width: $laptop - 1) {
  .container {
    width: 200px;
    border-radius: 3px;
    background-color: $blue-dialog-background;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;

    .text {
      font-family: $font-route159;
      font-weight: bold;
      color: white;
    }
  }
}

@media (min-width: $laptop) and (max-width: $desktop - 1) {
  .container {
    width: 200px;
    border-radius: 3px;
    background-color: $blue-dialog-background;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;

    .text {
      font-family: $font-route159;
      font-weight: bold;
      color: white;
    }
  }
}

@media (min-width: $desktop) {
  .container {
    width: 200px;
    border-radius: 3px;
    background-color: $blue-dialog-background;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;

    .text {
      font-family: $font-route159;
      font-weight: bold;
      color: white;
    }
  }
}
