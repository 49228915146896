@import "../../Styles/colors.scss";
@import "../../Styles/fonts.scss";
@import "../../Styles/animations.scss";
$mobile: 576px;
$tablet: 767px;
$laptop: 992px;
$desktop: 1200px;

// Extra mall devices (< 576px)
@media (max-width: $mobile - 1){
  .general {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 250px;
    width: 85vw;
    background-color: $blue-dialog-background;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
    border-radius: 15px;
    animation: modalLeftRight 0.2s, modalFade 0.25s;
  
    p {
      font-family: $font-nasalization;
      font-weight: 700;
      font-size: 22px;
      color: white;
      text-align: center;
    }
  }
  
  .cross {
    transform: scale(0.7);
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
  }
};

// Small devices (landscape phones, 576px and up)
@media (min-width: $mobile) and (max-width:$tablet - 1) {
  .general {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    background-color: $blue-dialog-background;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
    border-radius: 15px;
    animation: modalLeftRight 0.2s, modalFade 0.25s;
  
    p {
      font-family: $font-nasalization;
      font-weight: 700;
      font-size: 26px;
      color: white;
      text-align: center;
    }
  }
  
  .cross {
    transform: scale(0.7);
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $tablet) and (max-width: $laptop - 1){
    .general {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    background-color: $blue-dialog-background;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
    border-radius: 15px;
    animation: modalLeftRight 0.2s, modalFade 0.25s;

    p {
      font-family: $font-nasalization;
      font-weight: 700;
      font-size: 26px;
      color: white;
      text-align: center;
    }
  }

  .cross {
    transform: scale(0.7);
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $laptop) and (max-width: $desktop - 1){
  .general {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    background-color: $blue-dialog-background;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
    border-radius: 15px;
    animation: modalLeftRight 0.2s, modalFade 0.25s;
  
    p {
      font-family: $font-nasalization;
      font-weight: 700;
      font-size: 26px;
      color: white;
      text-align: center;
    }
  }
  
  .cross {
    transform: scale(0.7);
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $desktop){
  .general {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    background-color: $blue-dialog-background;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
    border-radius: 15px;
    animation: modalLeftRight 0.2s, modalFade 0.25s;
  
    p {
      font-family: $font-nasalization;
      font-weight: 700;
      font-size: 26px;
      color: white;
      text-align: center;
    }
  }
  
  .cross {
    transform: scale(0.7);
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
  }

}
