$desktop: 1200px;

@media (max-width: 1199px){
  .normal {
    position: relative;
    left: 2px;
    cursor: pointer;
  }
  
  .reload {
    position: relative;
    left: 2px;
    transform: rotate(720deg);
    transition: all 0.6s ease;
    cursor: no-drop;
  }
}

@media (min-width: $desktop){
  .normal {
      position: relative;
      right: 10px;
      cursor: pointer;
    }
    
    .reload {
      position: relative;
      right: 10px;
      transform: rotate(720deg);
      transition: all 0.6s ease;
      cursor: no-drop;
  }
}
